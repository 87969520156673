import { RouteConfig } from 'vue-router';

export const ReportRouteName = 'Report';

const reportRoutes: RouteConfig[] = [
    {
        path: '/report',
        name: ReportRouteName,
        component: () => import('@/features/report/report-app.vue'),
        meta: { authorize: true }
    }
];

export default reportRoutes;