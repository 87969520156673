import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store';

@Module({ namespaced: true, dynamic: true, store, name: 'user-activity' })
class UserActivityModule extends VuexModule {
    isActive = false;

    readonly inactivityLimit = 30000; // 30 seconds
    inactivityTimeout: number | null = null;

    readonly checkDelay = 5000; // 5 seconds delay before re-enabling tracking
    checkDelayTimeout: number | null = null;

    get UserIsActive() {
        return this.isActive;
    }

    @Mutation
    SET_ACTIVE(isActive: boolean) {
        this.isActive = isActive;
    }

    @Mutation
    SET_INACTIVITY_TIMEOUT(timeout: number | null) {
        this.inactivityTimeout = timeout;
    }

    @Mutation
    SET_CHECK_DELAY_TIMEOUT(timeout: number | null) {
        this.checkDelayTimeout = timeout;
    }

    @Action
    onUserEnter() {
        this.context.commit('SET_ACTIVE', true);
        this.trackUserActivity();
    }

    @Action
    onUserLeave() {
        this.context.commit('SET_ACTIVE', false);
        this.clearTimeouts();
        this.untrackUserActivity();
    }

    @Action
    enableTracking() {
        window.addEventListener('focus', this.onUserEnter);
        window.addEventListener('blur', this.onUserLeave);
        document.addEventListener('visibilitychange', this.onDocumentVisibilityChange);
        document.addEventListener('mouseenter', this.onUserEnter);
        document.addEventListener('mouseleave', this.onUserLeave);
    }

    @Action
    disableTracking() {
        this.clearTimeouts();
        this.untrackUserActivity();
        window.removeEventListener('focus', this.onUserEnter);
        window.removeEventListener('blur', this.onUserLeave);
        document.removeEventListener('visibilitychange', this.onDocumentVisibilityChange);
        document.removeEventListener('mouseenter', this.onUserEnter);
        document.removeEventListener('mouseleave', this.onUserLeave);
    }

    @Action
    onDocumentVisibilityChange() {
        if (document.visibilityState === 'visible') {
            this.onUserEnter();
        } else {
            this.onUserLeave();
        }
    }

    @Action
    trackUserActivity() {
        document.addEventListener('mousemove', this.resetInactivityTimer);
        document.addEventListener('keydown', this.resetInactivityTimer);
        document.addEventListener('click', this.resetInactivityTimer);
    }

    @Action
    untrackUserActivity() {
        document.removeEventListener('mousemove', this.resetInactivityTimer);
        document.removeEventListener('keydown', this.resetInactivityTimer);
        document.removeEventListener('click', this.resetInactivityTimer);
    }

    @Action
    resetInactivityTimer() {
        this.context.commit('SET_ACTIVE', true);
        this.untrackUserActivity();
        this.clearTimeouts();
        this.context.commit('SET_INACTIVITY_TIMEOUT', setTimeout(() => {
            this.context.commit('SET_ACTIVE', false);
        }, this.inactivityLimit));
        this.context.commit('SET_CHECK_DELAY_TIMEOUT', setTimeout(() => {
            this.trackUserActivity();
        }, this.checkDelay));
    }

    @Action
    clearTimeouts() {
        clearTimeout(this.inactivityTimeout as number);
        this.context.commit('SET_INACTIVITY_TIMEOUT', null);
        clearTimeout(this.checkDelayTimeout as number);
        this.context.commit('SET_CHECK_DELAY_TIMEOUT', null);
    }
}

export const UserActivity = getModule(UserActivityModule);
