import { IAttendantDto, ICustomerServiceDto } from "@/clients/chat-client";
import { Guid } from "@/utils/guid";
import { Conversation } from "./conversation";

export class CustomerService {
    public conversation: Conversation;
    public id: Guid;
    public startedAt: Date;

    public attendants: IAttendantDto[];

    public protocol?: string = "";
    public serviceStartedByMessageId?: Guid;

    private _serviceQueueId: Guid | null = null;
    public get serviceQueueId(): Guid | null {
        return this._serviceQueueId;
    }
    public set serviceQueueId(v: Guid | null) {
        this._serviceQueueId = v;
        this.conversation.updateQueue(v);
    }



    constructor(conversation: Conversation, data: ICustomerServiceDto) {
        this.conversation = conversation;
        if (this.conversation == null) {
            throw new Error("Conversation is null");
        }
        this.id = data.id;
        this.startedAt = new Date(data.startedAt);
        this.serviceQueueId = data.serviceQueueId;
        this.attendants = data.attendants ?? [];
        this.protocol = data.protocol;
        this.serviceStartedByMessageId = data.serviceStartedByMessageId;
    }
}
