import { AuthorizationModule } from "@/store/modules/authorization";
import { AuthrorizationPaths, QueryParameterNames } from "@/store/modules/authorization/constants";
import axios from "axios";
import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import type { Dictionary } from "vue-router/types/router";
import router from "@/router";
import { Api } from "@/typings/api.typings";
import Result = Api.Result;
import { Guid } from "@/utils/guid";

export class BaseClient {
    protected readonly instance: AxiosInstance;
    protected readonly baseUrl: string;

    constructor(baseUrl?: string, instance?: AxiosInstance) {
        this.instance = this.setAxiosInstance(instance ?? axios.create());
        this.baseUrl = baseUrl != null ? baseUrl : process.env.VUE_APP_API_URL;
    }

    private setAxiosInstance(instance: AxiosInstance): AxiosInstance {
        instance.interceptors.response.use(response => {
            if (response?.headers['content-type']?.includes('application/json') ?? false) {
                response.data = JSON.parse(JSON.stringify(response.data), Guid.guidReviver);
            }

            return response;
        }, error => {
            // Authentication errors automatically take the user to the login screen
            if (error.response.status === 401) {
                if (router.currentRoute.path !== AuthrorizationPaths.Login) {
                    console.log('error', error);
                    const query: Dictionary<string> = {};

                    query[QueryParameterNames.ReturnUrl] = window.location.pathname;

                    router.push({
                        path: AuthrorizationPaths.Login,
                        query
                    });
                }
                return null;
            }

            return Promise.reject(error);
        });

        return instance;
    }

    protected getAccessToken(): string {
        return AuthorizationModule.accessToken;
    }

    protected getParams(query: { [key: string]: unknown; } | unknown): string {
        if (query == null || Array.isArray(query) === true)
            return '';

        const values = (query as { [key: string]: unknown; });

        const params: string[] = [];
        for (const key in values) {
            let value = values[key];

            if (typeof value === 'object') {
                if (value instanceof Date) {
                    value = value.toISOString();
                }
            }

            if (value == null)
                continue;
            params.push(`${key}=${value}`);
        }
        return params.join("&");
    }

    private isAxiosError<T>(error: Error | AxiosError): error is AxiosError<T> {
        return error && (<AxiosError> error).isAxiosError === true;
    }

    protected throwException(message: string, status: number, response: unknown, headers: { [key: string]: unknown; }, result?: unknown): void {
        if (result !== null && result !== undefined) {
            throw result;
        }

        throw new ApiException(message, status, response, headers, null);
    }

    protected async sendRequest<T>(options: AxiosRequestConfig): Promise<T> {
        try {
            const response = await this.instance.request(options);
            return this.processResponse(response);
        } catch (ex) {
            const _error = ex as (Error | AxiosError);

            if (this.isAxiosError<(T | Result)>(_error) && _error.response) {
                return this.processResponse(_error.response);
            }

            throw _error;
        }
    }

    protected processResponse<T>(response: AxiosResponse): Promise<T> {
        const status = response.status;
        const _headers: { [key: string]: unknown; } = {};
        if (response.headers && typeof response.headers === "object") {
            for (const k in response.headers) {
                if (Object.prototype.hasOwnProperty.call(response.headers, k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            return response.data;
        }
        if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            this.throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<T>(<T> null);
    }
}

export class ApiException extends Error {
    message: string;
    status: number;
    response: unknown;
    headers: { [key: string]: unknown; };
    result: unknown;

    constructor(message: string, status: number, response: unknown, headers: { [key: string]: unknown; }, result?: unknown) {
        super();

        this.message = message;
        this.status = status;
        this.response = response;
        this.headers = headers;
        this.result = result;
    }

    protected isApiException = true;

    static isApiException(obj: unknown): obj is ApiException {
        return (<ApiException> obj).isApiException === true;
    }
}
