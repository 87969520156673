/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { isValidNumber, parseNumber, ParsedNumber } from "libphonenumber-js";

/* eslint-disable @typescript-eslint/no-explicit-any */
type DynamicParam<T> = () => T;

type Param<T> = T | DynamicParam<T>;
type Func<TResult> = () => TResult;

/**
 * Retorna o valor de um parâmetro. Se o parâmetro for uma função, então ela é executada e seu resultado é retornado.
 * @param params
 */
function getParam<T>(params: Param<T>): T {
    if (typeof params === 'function')
        return (params as Func<T>)();

    return params;
}

/**
 * Retorna true se o valor de um campo for considerado como preenchido.
 * @param value
 */
const req = (value: any) => {
    if (Array.isArray(value)) return !!value.length;
    if (value === undefined || value === null) {
        return false;
    }

    if (value === false) {
        return false;
    }

    if (value instanceof Date) {
        // invalid date won't pass
        return !isNaN(value.getTime());
    }

    if (typeof value === 'object') {
        for (const _ in value) return true;
        return false;
    }

    return !!String(value).length;
};

/**
 * Obriga o preenchimento do campo.
 * @param value
 */
export function required(value: any): string | boolean {

    if (!req(value))
        return 'Preencha este campo.';

    return true;
}

export function requiredWithMessage(message: Param<string> = 'Preencha este campo.') {
    return (value: any) => {
        if (!req(value))
            return getParam(message);

        return true;
    };
}

/**
 * Obriga o preenchimento do campo somente se condition for válida.
 * @param condition Condição em que o campo deve ser preenchido.
 */
export function requiredIf(condition: Param<boolean>) {
    return (value: any) => {
        const vv = getParam(condition);
        if (vv)
            return required(value);
        return true;
    };
}

export function requiredIfWithMessage(condition: Param<boolean>, message: Param<string> = 'Preencha este campo.') {
    return (value: any) => {
        if (getParam(condition))
            if (!req(value))
                return getParam(message);

        return true;
    };
}

/**
 * Obriga o preenchimento do campo a não ser que a condição seja válida.
 * @param condition
 */
export function requiredUnless(condition: Param<boolean>) {
    return (value: any) => {
        if (!getParam(condition))
            return required(value);

        return true;
    };
}

function countDescription(count: number) {
    return count == 1 ? '1 caracter' : `${count} caracteres`;
}

export function greaterThan(comparteTo: Param<number>) {
    return (value: string) => {
        if (!req(value))
            return true;

        const comparteToValue = getParam(comparteTo);

        if (Number(value) > comparteToValue) {
            return true;
        }

        return `Valor deve ser maior que ${comparteToValue}`;
    };
}
export function greaterThanOrEqualTo(comparteTo: Param<number>) {
    return (value: string) => {
        if (!req(value))
            return true;

        const comparteToValue = getParam(comparteTo);

        if (Number(value) >= comparteToValue) {
            return true;
        }

        return `Valor deve ser maior ou igual a ${comparteToValue}`;
    };
}
export function lessThan(comparteTo: Param<number>) {
    return (value: string) => {
        if (!req(value))
            return true;

        const comparteToValue = getParam(comparteTo);

        if (Number(value) < comparteToValue) {
            return true;
        }

        return `Valor deve ser menor que ${comparteToValue}`;
    };
}
export function lessThanOrEqualTo(comparteTo: Param<number>) {
    return (value: string) => {
        if (!req(value))
            return true;

        const comparteToValue = getParam(comparteTo);

        if (Number(value) <= comparteToValue) {
            return true;
        }

        return `Valor deve ser menor ou igual a ${comparteToValue}`;
    };
}

/**
 * Obriga um tamanho máximo de texto.
 * @param max Quantidade máxima de caracteres. Pode ser passada uma função para que a quantidade seja dinâmica.
 */
export function maxLength(max: Param<number>) {
    return (value: string) => {
        if (!req(value))
            return true;

        const maxValue = getParam(max);

        if (typeof value === 'string' && value.length > maxValue)
            return `Tamanho máximo de ${countDescription(maxValue)}, você preencheu ${countDescription(value.length)}`;

        return true;
    };
}

/**
 * Obriga um tamanho específico de texto.
 * @param size Quantidade de caracteres. Pode ser passada uma função para que a quantidade seja dinâmica.
 */
export function length(size: Param<number>) {
    return (value: string) => {
        if (!req(value))
            return true;

        const sizeValue = getParam(size);

        if (typeof value === 'string' && value.length !== sizeValue)
            return `Tamanho deve ser ${countDescription(sizeValue)}.`;

        return true;
    };
}

/**
 * Obriga um tamanho mínimo de texto.
 * @param min Quantidade mínima de caracteres. Pode ser passada uma função para que a configuração seja dinâmica.
 */
export function minLength(min: Param<number>) {
    return (value: string) => {
        debugger;
        if (!req(value))
            return true;

        const minValue = getParam(min);

        if (!req(value) || typeof value === 'string' && value.length < minValue)
            return `Tamanho mínimo de ${countDescription(minValue)} caracteres, você preencheu ${countDescription(value.length)}.`;

        return true;
    };
}

const alphaRegex = /^[a-zA-Z]*$/;

/**
 * Obriga somente letras (sem espaço e acentuação).
 * @param value
 */
export function alpha(value: string) {
    if (req(value) && !alphaRegex.test(value))
        return 'Informe somente letras.';

    return true;
}

const alphaNumRegex = /^[a-zA-Z0-9]*$/;

/**
 * Obriga somente letras e números (sem espaço e acentuação).
 * @param value
 */
export function alphaNum(value: string) {
    if (req(value) && !alphaNumRegex.test(value))
        return 'Informe somente letras ou números.';

    return true;
}

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Obriga que o texto esteja em formato de email.
 * @param value
 */
export function email(value: string) {
    if (req(value) && !emailRegex.test(value))
        return 'E-mail inválido.';

    return true;
}

/**
 * Obriga que o valor esteja entre os parâmetros passados.
 * @param min Valor mínimo (>=). Pode ser passada uma função para que a configuração seja dinâmica.
 * @param max Valor máximo (<=). Pode ser passada uma função para que a configuração seja dinâmica.
 */
export function between(min: Param<number | Date>, max: Param<number | Date>) {
    return (value: string) => {
        if (!req(value))
            return true;

        const minValue = getParam(min);
        const maxValue = getParam(max);

        if (+value < +minValue || +value > +maxValue)
            return `Deve estar entre ${minValue} e ${maxValue}.`;

        return true;
    };
}

/**
 * Obriga que o valor seja maior ou igual ao parâmetro.
 * @param min Valor mínimo. Pode ser passada uma função para que a configuração seja dinâmica.
 */
export function minValue(min: Param<number | Date>) {
    return (value: string) => {
        if (!req(value))
            return true;

        const minValue = getParam(min);

        if (+value < +minValue)
            return `Deve ser maior ou igual a ${minValue}.`;

        return true;
    };
}

/**
 * Obriga que o valor seja menor ou igual ao parâmetro.
 * @param max Valor máximo. Pode ser passada uma função para que a configuração seja dinâmica.
 */
export function maxValue(max: Param<number | Date>) {
    return (value: string) => {
        if (!req(value))
            return true;

        const maxValue = getParam(max);

        if (+value > +maxValue)
            return `Deve ser menor ou igual a ${maxValue}.`;

        return true;
    };
}

const decimalRegex = /^[-]?\d*(\.\d+)?$/;

/**
 * Obriga um decimal válido (formato en-US).
 * @param value
 */
export function decimal(value: number) {
    if (req(value) && !decimalRegex.test(value as any))
        return 'Número decimal inválido.';

    return true;
}

const decimalPtBrRegex = /^[-]?\d*(,\d+)?$/;

/**
 * Obriga um decimal válido (formato pt-BR).
 * @param value
 */
export function decimalPtBr(value: number) {
    if (req(value) && !decimalPtBrRegex.test(value as any))
        return 'Número decimal inválido.';

    return true;
}

const integerRegex = /(^[0-9]*$)|(^-[0-9]+$)/;

/**
 * Obriga um número inteiro (incluindo número negativo)
 * @param value
 */
export function integer(value: number) {
    if (req(value) && !integerRegex.test(value as any))
        return 'Número inteiro inválido.';

    return true;
}

const numericRegex = /^[0-9]*$/;

/**
 * Obriga um número inteiro positivo.
 * @param value
 */
export function numeric(value: string): boolean | string {
    if (req(value) && !numericRegex.test(value))
        return 'Número inválido.';

    return true;
}

/**
 * Obriga que o valor esteja de acordo com a expressão regular
 * @param regex
 */
export function match(regex: Param<RegExp>) {
    return (value: string) => {
        const regexValue = getParam(regex);

        if (req(value) && !regexValue.test(value as any))
            return 'Formato inválido.';

        return true;
    };
}

export function unique(uniqueValues: Param<any[]>, message?: Param<string>) {
    return (value: string) => {
        const unique = getParam(uniqueValues);
        const msg = getParam(message ?? '') || 'Valor não é único.';

        if (unique.filter(f => f == value).length > 1)
            return msg;

        return true;
    };
}

/**
 * Não permite campo vazio
 * @param value
 */
export function notEmpty(value: any): string | boolean {

    if (String.isNullOrWhiteSpace(value))
        return 'Preencha este campo.';

    return true;
}

export function uri(value: string): string | boolean {
    try {
        new URL(value);
    } catch {
        return "Url inválida";
    }

    return true;
}

export function phone(value: string) {
    value = value.startsWith('+') ? value : '+' + value;
    const parsedPhoneNumber = parseNumber(value);

    if (parsedPhoneNumber && isParsedNumber(parsedPhoneNumber)) {
        if (!isValidNumber(parsedPhoneNumber as ParsedNumber)) {
            return "Número inválido";
        }

        return true;
    }

    return "Número não está no formato internacional";
}


function isParsedNumber(obj: any): obj is ParsedNumber {
    return 'phone' in obj;
}
