import { Guid } from '@/utils/guid';
import { RouteConfig, Location } from 'vue-router';

const UserListName = 'UserList';
const UserInsertName = 'UserInsert'
const UserEditName = 'UserEdit'

export const UserListRoute: Location = {
    name: UserListName
}

export const UserInsertRoute: Location = {
    name: UserInsertName
}

export const UserEditRoute = (id: Guid, email: string): Location => ({
    name: UserEditName,
    params: {
        id: id.toString(),
        email
    }
});

export const UserCompleteRegistrationPath = '/users/complete-registration';
const UserCompleteRegistrationName = 'UserCompleteRegistration';
export const UserCompleteRegistrationRoute: Location = {
    name: UserCompleteRegistrationName
}

const userRoutes: RouteConfig[] = [
    {
        path: '/users',
        name: UserListName,
        component: () => import('@/features/registration/users/users-list.vue'),
        meta: { authorize: true }
    },
    {
        path: '/users/insert',
        name: UserInsertName,
        component: () => import('@/features/registration/users/user-insert-edit.vue'),
        meta: { authorize: true },
    }, 
    {
        path: '/users/edit/:id',
        name: UserEditName,
        component: () => import('@/features/registration/users/user-insert-edit.vue'),
        meta: { authorize: true },
        props: (route) => route.params
    }, 
    {
        path: UserCompleteRegistrationPath,
        name: UserCompleteRegistrationName,
        component: () => import('@/features/registration/users/user-complete-registration.vue'),
        meta: { authorize: true }
    }
];

export default userRoutes;