import { Guid } from '@/utils/guid';
import { RouteConfig, Location } from 'vue-router';

const ReportListName = 'ReportList';
const ReportInsertName = 'ReportInsert'
const ReportEditName = 'ReportEdit'
const ReportQuestionInsertName = 'ReportQuestionInsert'
const ReportQuestionEditName = 'ReportQuestionEdit'

export const ReportListRoute: Location = {
    name: ReportListName
}

export const ReportInsertRoute: Location = {
    name: ReportInsertName
}

export const ReportQuestionInsertRoute: Location = {
    name: ReportQuestionInsertName
}

export const ReportEditRoute = (id: string): Location => ({
    name: ReportEditName,
    params: {
        reportId: id
    }
});

export const ReportQuestionEditRoute = (reportId: Guid): Location => ({
    name: ReportQuestionEditName,
    params: {
        reportId: reportId.toString()
    }
});

const reportsRoutes: RouteConfig[] = [
    {
        path: '/reports',
        name: ReportListName,
        component: () => import('@/features/registration/reports/report-list.vue'),
        meta: { authorize: true }
    },
    {
        path: '/reports/insert',
        name: ReportInsertName,
        component: () => import('@/features/registration/reports/report-add.vue'),
        meta: { authorize: true },
    }, 
    {
        path: '/reports/edit/:reportId',
        name: ReportEditName,
        component: () => import('@/features/registration/reports/report-edit.vue'),
        meta: { authorize: true },
        props: (route) => route.params
    }
];

export default reportsRoutes;