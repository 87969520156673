<template>
    <v-dialog v-if="isMobile" class="mt-4">
        <template v-slot:activator="{ on, attrs }">
            <v-card class="card-button" v-bind="attrs" v-on="on">
                <v-card-title class="justify-center"> MENU </v-card-title>
                <v-card-text class="text-center text-truncate"> {{ cardTitle? cardTitle : 'Clique aqui para editar' }} </v-card-text>
            </v-card>
        </template>
        <menu-box-card :values="values" :remove="remove"></menu-box-card>
    </v-dialog>

    <menu-box-card v-else :values="values" :remove="remove"></menu-box-card>
</template>

<style scoped>
.card-button {
    width: 190px;
    height: 95px;
    padding: 0px;
}
</style>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import MenuBoxCard from "@/features/registration/companies/menus/menu-box-card.vue";

@Component({
    name: "MenuBox",
    components: {
        MenuBoxCard
    }
})
export default class MenuBox extends Vue {
    @Prop() remove: unknown;

    get isMobile() {
        return this.$vuetify.breakpoint.mobile;
    }

    get values(): any {
        const value = this.$attrs.node as any;

        if ("data" in value) {
            return value.data;
        } else return value;
    }

    get cardTitle(): string {
        return this.values.title
    }
}
</script>
