declare global {
    interface Date {
        toISOTimeString(): string;
        toISODateString(): string;
    }
}

Date.prototype.toISOTimeString = function () {
    return this.toISOString().split('T')[1].split('.')[0];
}

Date.prototype.toISODateString = function () {
    return this.toISOString().split('T')[0];
}

export { };