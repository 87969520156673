/* eslint-disable @typescript-eslint/no-explicit-any */
enum ErrorCode {
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    InternalServerError = 500,
}

export class ResultError {
    private _message = '';

    get message(): string {
        return this._message;
    }

    constructor(message: string);
    constructor(errorCode: ErrorCode, message: string);
    constructor(id: number, message: string);
    constructor(arg1: string | ErrorCode | number, arg2?: string) {
        if (typeof arg1 === 'string') {
            this._message = arg1;
        } else if (typeof arg1 === 'number' && typeof arg2 === 'string') {
            this._message = arg2;
            this.id = arg1;
        } else if (typeof arg1 === 'number' && typeof arg2 === 'undefined') {
            this.id = arg1;
        }
    }

    readonly id: number = 0;

    toString(): string {
        return this.id !== 0 ? `${this.id}: ${this.message}` : this.message;
    }

    public static implicitToString(error: ResultError | null | undefined): string | null {
        return error?.toString() || null;
    }

    public static implicitToError(error: string | null | undefined): ResultError | null {
        return error ? new ResultError(error) : null;
    }

    public static toErrors(errors: string[] | null | undefined): ResultError[] | null {
        return errors?.map((s) => new ResultError(s)) || null;
    }
}

export class Result {
    private _success: boolean;
    private _errors: ResultError[];

    get success(): boolean {
        return this._success;
    }

    get failure(): boolean {
        return !this._success;
    }

    get errors(): ResultError[] {
        return this._errors;
    }

    protected constructor(result: Result);
    protected constructor(success: boolean, ...errors: ResultError[]);
    protected constructor(successOrResult: boolean | Result, ...errors: ResultError[]) {
        if (successOrResult instanceof Result) {
            this._errors = successOrResult.errors;
            this._success = successOrResult.success;
        } else {
            const success = successOrResult;
            if (success && errors.length > 0) {
                throw new ResultError(`Parameter 'success' cannot be true when 'errors' has content`);
            }
            if (!success && errors.length <= 0) {
                throw new ResultError(`Parameter 'success' cannot be false when 'errors' has no content`);
            }

            this._success = success;
            this._errors = errors;
        }
    }

    public static fail(...errors: string[]): Result;
    public static fail<T>(result: ResultWithValue<T>): Result;
    public static fail(errorCode: number, message: string): Result;
    public static fail(code: number, message: string): Result;
    public static fail(errors: string[], ...nestedErrors: string[]): Result;
    public static fail(errors: ResultError[], ...nestedErrors: ResultError[]): Result;
    public static fail<T>(errors: string[], ...nestedErrors: string[]): ResultWithValue<T>;
    public static fail<T>(errors: ResultError[], ...nestedErrors: ResultError[]): ResultWithValue<T>;
    public static fail<T>(result: Result): ResultWithValue<T>;

    static fail(...args: any[]): Result {
        if (args[0] instanceof Result) {
            const result = args[0] as Result;
            return new Result(result);
        } else if (typeof args[0] === 'number' && typeof args[1] === 'string') {
            return new Result(false, new ResultError(args[0], args[1]));
        } else if (args[0] instanceof Array && args[0][0] instanceof ResultError) {
            return new Result(false, ...args[0]);
        } else {
            return new Result(false, ...args.map((error: string) => new ResultError(0, error)));
        }
    }

    public static ok(): Result;
    public static ok<T>(value: T): ResultWithValue<T>;

    static ok(...args: any[]): Result {
        if (args == null || args.length <= 0) {
            return new Result(true);
        }

        return new ResultWithValue(args[0], true);
    }

    public static notFound(name: string, key: any): Result {
        return Result.fail(0, `Entity "${name}" (${key}) was not found.`);
    }

    public static combine(...results: Result[]): Result {
        for (const result of results) {
            if (result.failure) {
                return result;
            }
        }

        return Result.ok();
    }

    public static parse(json: string): Result {
        const obj = typeof json === "object" ? json : JSON.parse(json);
        if (obj.success) {
            return Result.ok();
        }

        return Result.fail(obj.errors);
    }
}

export class ResultWithValue<T> extends Result {
    private readonly _value: T;

    get value(): T {
        if (this.failure) {
            throw new ResultError("Result has failed");
        }

        return this._value;
    }

    constructor(result: Result);
    constructor(value: T, success: boolean, ...errors: string[]);
    constructor(value: T, success: boolean, ...errors: ResultError[]);
    constructor(valueOrResult: T | Result, success?: boolean, ...errors: (ResultError | string)[]) {
        if (valueOrResult instanceof Result) {
            super(valueOrResult);
            this._value = null as unknown as T;
        } else {
            const value = valueOrResult;
            super(success as boolean, ...(errors.map(error => (typeof error === 'string' ? new ResultError(0, error) : error)) as ResultError[]));
            this._value = success ? value : null as unknown as T;
        }
    }
}
