import { Profile, SignoutResponse, User, UserManager } from "oidc-client";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { OpenIdClientSettings } from "./constants";
import store from "@/store";
import { UserClient } from "@/clients/user-client";
import { Guid } from "@/utils/guid";

const userManager = new UserManager(OpenIdClientSettings);

export enum AuthenticationResultStatus {
    Redirect = 'redirect',
    Success = 'success',
    Fail = 'fail',
}

export interface State {
    returnUrl?: string;
}

export interface AuthResult {
    status: AuthenticationResultStatus,
    state?: State,
    message?: string,
}

export interface Subscriber {
    callback: (isAuth: boolean) => Promise<void>;
    id: number;
}

const createArguments = (state?: State) => {
    return {
        useReplaceToNavigate: true,
        data: state,
    };
};

const error = (message: string | unknown, defaultMessage?: string): AuthResult => {
    const msg = typeof message === "string"
        ? message
        : message instanceof Error
            ? message.message
            : defaultMessage;

    return { status: AuthenticationResultStatus.Fail, message: msg };
};

const redirect = (): AuthResult => {
    return { status: AuthenticationResultStatus.Redirect };
};

const success = (state?: State): AuthResult => {
    return { status: AuthenticationResultStatus.Success, state };
};

const signinRedirectAsync = async (state?: State): Promise<AuthResult> => {
    await userManager.signinRedirect(createArguments(state));
    return redirect();
};

const signinSilentAsync = async (state?: State): Promise<AuthResult> => {
    const user = await userManager.signinSilent(createArguments(state));

    if (user == null) {
        return error("user is undefined");
    } else {
        return success(state);
    }
};

@Module({ dynamic: true, store, name: 'authorization' })
class Authorization extends VuexModule {
    private user: User | null = null;
    private subscribers: Subscriber[] = [];
    private nextSubscriptionId = 0;
    private displayName = "";

    get isAuthenticated(): boolean {
        return (this.user?.profile ?? null) != null &&
            (this.user?.access_token ?? null) != null;
    }

    get accessToken(): string {
        if (this.user != null && this.user.access_token != null) {
            return this.user.access_token;
        }

        throw error("User not authenticated");
    }

    get userProfile(): Profile {
        if (this.user != null && this.user.profile != null) {
            return this.user.profile;
        }

        if (!String.isNullOrWhiteSpace(process.env.CORDOVA_PLATFORM)) {
            const cacheUser = window.localStorage.getItem('user');
            if (!String.isNullOrWhiteSpace(cacheUser)) {
                this.user = JSON.parse(cacheUser);
            }
        }

        throw error("User not authenticated");
    }

    get userId(): Guid {
        return Guid.parse(this.userProfile.sub);
    }

    get getDisplayName(): string {
        return this.displayName;
    }

    @Mutation
    public SET_DISPLAY_NAME(displayName: string): void {
        this.displayName = displayName;
    }

    @Mutation
    private SET_USER(user: User | null): void {
        this.user = user;
    }

    @Mutation
    private ADD_SUBSCRIBE(callback: (isAuth: boolean) => Promise<void>): void {
        const nextId = this.nextSubscriptionId += 1;
        this.subscribers.push({
            callback,
            id: nextId
        });
    }

    @Mutation
    private REMOVE_SUBSCRIBE(subscriptionId: number): void {
        const subscriptionIndex = this.subscribers.findIndex(f => f.id === subscriptionId);

        if (subscriptionIndex >= 0) {
            this.subscribers.splice(subscriptionIndex, 1);
        }
    }

    @Action({ rawError: true })
    public async changeUser(user: User): Promise<void> {
        this.SET_USER(user);
        const isAuth = this.isAuthenticated;
        const tasks = this.subscribers.map(subscriber => subscriber.callback(isAuth));
        for (const task of tasks) {
            await task;
        }
    }

    @Action
    public async CleanUser(): Promise<void> {
        this.SET_USER(null);
        await this.notifySubscribers();
    }

    @Action
    public async notifySubscribers(): Promise<void> {
        const isAuth = this.isAuthenticated;
        const tasks = this.subscribers.map(subscriber => subscriber.callback(isAuth));
        for (const task of tasks) {
            await task;
        }
    }

    @Action
    public subscribe(callback: (isAuth: boolean) => Promise<void>): Promise<number> {
        this.ADD_SUBSCRIBE(callback);
        const id = this.nextSubscriptionId - 1;
        return Promise.resolve(id);
    }

    @Action
    public unsubscribe(subscriptionId: number): Promise<void> {
        this.REMOVE_SUBSCRIBE(Number(subscriptionId));
        return Promise.resolve();
    }

    @Action
    public async signInAsync(state: State): Promise<AuthResult> {
        try {
            return await signinSilentAsync(state);
        } catch (silentError) {
            try {
                return await signinRedirectAsync(state);
            } catch (redirectError) {
                return error(redirectError);
            }
        }
    }

    @Action
    public async completeSignInAsync(url: string): Promise<AuthResult> {
        try {
            const user = await userManager.signinCallback(url);

            if (user == null) {
                return error("User is undefined.");
            } else {
                return success(user && user.state);
            }
        } catch {
            return error('There was an error signing in.');
        }
    }

    @Action
    public async signOutAsync(state: State): Promise<AuthResult> {
        try {
            await userManager.signoutRedirect(createArguments(state));
            return redirect();
        } catch (redirectSignOutError) {
            return error(redirectSignOutError);
        }
    }

    @Action
    public async completeSignOutAsync(url: string): Promise<AuthResult> {
        try {
            const response = await userManager.signoutCallback(url) as SignoutResponse;
            return success((response.state) as State);
        } catch (ex) {
            return error(ex);
        }
    }

    @Action
    public async loadDisplayName(): Promise<void> {
        const client = new UserClient();
        const data = await client.getDisplayName();
        this.SET_DISPLAY_NAME(data);
    }

    get userHasRoles() {
        return (...roles: string[]) => {
            if (!AuthorizationModule.isAuthenticated) {
                return false;
            }

            const userRoles = (this.userProfile.role ?? []) as string[];

            if (userRoles.length <= 0) {
                return false;
            }

            return roles.every(role => userRoles.includes(role));
        };
    }
}

export const AuthorizationModule = getModule(Authorization);

userManager.events.addUserLoaded(user => AuthorizationModule.changeUser(user));
userManager.events.addUserUnloaded(() => AuthorizationModule.CleanUser());
