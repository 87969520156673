import { RouteConfig, Location } from 'vue-router';
import { Guid } from '@/utils/guid';

const MenuListName = 'MenuList';
const MenuInsertName = 'MenuInsert'
const MenuEditName = 'MenuEdit'

export const MenuListRoute: Location = {
    name: MenuListName
}

export const MenuInsertRoute: Location = {
    name: MenuInsertName
}

export const MenuEditRoute = (id: Guid, title: string): Location => ({
    name: MenuEditName,
    params: {
        id: id.toString(),
        title
    }
});

export const MenuCompleteRegistrationPath = '/menus/complete-registration';
const MenuCompleteRegistrationName = 'MenuCompleteRegistration';
export const MenuCompleteRegistrationRoute: Location = {
    name: MenuCompleteRegistrationName
}

const menuRoutes: RouteConfig[] = [
    {
        path: '/menus',
        name: MenuListName,
        component: () => import('@/features/registration/companies/menus/menus-list.vue'),
        meta: { authorize: true }
    },
    {
        path: '/menus/insert',
        name: MenuInsertName,
        component: () => import('@/features/registration/companies/menus/menu-insert-edit.vue'),
        meta: { authorize: true },
    }, 
    {
        path: '/menus/edit/:id',
        name: MenuEditName,
        component: () => import('@/features/registration/companies/menus/menu-insert-edit.vue'),
        meta: { authorize: true },
        props: (route) => route.params
    }, 
    // {
    //     path: MenuCompleteRegistrationPath,
    //     name: MenuCompleteRegistrationName,
    //     component: () => import('@/features/registration/companies/menus/menu-complete-registration.vue'),
    //     meta: { authorize: true }
    // }
];

export default menuRoutes;