export function encode(value: string): string {
    return window
        .btoa(fixedEncodeURIComponent(value))
        .replaceAll("+", "-")
        .replaceAll("/", "_")
        .replaceAll("=", "");
}

export function decode(value: string): string {
    return decodeURIComponent(window.atob(value.replaceAll("-", "+").replaceAll("_", "/")));
}

function fixedEncodeURIComponent(str: string) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
        return "%" + c.charCodeAt(0).toString(16);
    });
}
