import { Guid } from "@/utils/guid";
import { Api } from "@/typings/api.typings";
import { Dictionary } from "./app";
import Reports = Api.Reports;
import FieldType = Reports.FieldType;
import ValidationRuleType = Reports.ValidationRuleType;
import DefaultMessageType = Api.Domain.Enums.DefaultMessageType;
import UserLevel = Api.Domain.Enums.UserLevel;

export namespace Authorization {
    export enum AlertType {
        Info = "info",
        Error = "error",
        Success = "success",
    }
}
export namespace Chat {
    export enum MessageFlow {
        None = 0,
        Inbound = 1,
        Outbound = 2,
    }
    export enum MessageType {
        None = 0,
        Text = 1,
        Image = 2,
        File = 3,
        Audio = 4,
        Video = 5,
        Contact = 6,
        Location = 7,
        Sticker = 8,
        List = 9,
        ListReply = 10,
        Buttons = 11,
        ButtonsReply = 12,
        Template = 13,
        Reaction = 14
    }
    export enum MessengerType {
        None = 0,
        WhatsApp = 1,
        Telegram = 2,
        FacebookMessenger = 3,
        Instagram = 4,
    }

    export interface IMessage {
        flow: MessageFlow;
        messenger: MessengerType;
        type: MessageType;
        id: Guid | string;
        contextId?: string | null;
        dateTime: string;
        sender: IPerson;
        receiver?: IPerson;
        payload: IPayload;
    }

    export interface IMessagePayload<TPayload extends IPayload>
        extends IMessage {
        payload: TPayload;
    }

    export class Message implements Omit<IMessage, "dateTime"> {
        flow!: MessageFlow;
        messenger!: MessengerType;
        type!: MessageType;
        id: Guid | string;
        contextId?: string | null;
        dateTime!: Date;
        sender!: IPerson;
        receiver?: IPerson;
        payload!: IPayload;

        constructor(data: IMessage) {
            this.flow = data.flow;
            this.messenger = data.messenger;
            this.type = data.type;
            this.id = data.id;
            this.contextId = data.contextId;
            this.dateTime = new Date(data.dateTime);
            this.sender = data.sender;
            this.receiver = data.receiver;

            switch (data.type) {
                case MessageType.Image:
                    this.payload = new ImageMessagePayload(
                        data.payload as IImageMessagePayload
                    );
                    break;

                case MessageType.Audio:
                    this.payload = new AudioMessagePayload(
                        data.payload as IAudioMessagePayload
                    );
                    break;
                case MessageType.Video:
                    this.payload = new VideoMessagePayload(
                        data.payload as IVideoMessagePayload
                    );
                    break;

                case MessageType.File:
                    this.payload = new FileMessagePayload(
                        data.payload as IFileMessagePayload
                    );
                    break;

                case MessageType.Sticker:
                    this.payload = new StickerMessagePayload(
                        data.payload as IStickerMessagePayload
                    );
                    break;

                default:
                    this.payload = data.payload;
                    break;
            }
        }
    }

    export interface IPayload { }

    export interface ITextMessagePayload extends IPayload {
        text: string;
    }

    export interface ITemplateMessagePayload extends IPayload {
        messageTemplateId: Guid;
        variables: {
            header?: Dictionary<string, string>;
            content: Dictionary<string, string>;
            footer?: Dictionary<string, string>;
        };
    }

    export interface IMediaMessagePayload extends IPayload {
        url: string;
        contentType: string;
        urlExpiry?: Date | null;
    }

    export class MediaMessagePayload implements IMediaMessagePayload {
        readonly url!: string;
        readonly contentType!: string;
        readonly urlExpiry?: Date | null;

        get urlExpired() {
            return this.urlExpiry == null ? false : new Date(this.urlExpiry) < new Date();
        }

        onUrlExpired(callback: Action): number {
            if (this.urlExpiry == null) {
                return -1;
            }

            const timeout = setTimeout((() => {
                if (this.urlExpired) {
                    clearTimeout(timeout);
                    callback();
                }
            }).bind(this), this.urlExpiry.getTime() - new Date().getTime());

            return timeout;
        }

        constructor(data: IMediaMessagePayload) {
            this.url = data.url;
            this.contentType = data.contentType;
            this.urlExpiry =
                data.urlExpiry != null ? new Date(data.urlExpiry) : undefined;
        }
    }

    export interface IVideoMessagePayload extends IMediaMessagePayload {
        caption?: string | null;
    }
    export class VideoMessagePayload
        extends MediaMessagePayload
        implements IVideoMessagePayload {
        caption?: string | null;

        constructor(data: IVideoMessagePayload) {
            super(data);
            this.caption = data.caption;
        }
    }


    export interface IImageMessagePayload extends IMediaMessagePayload {
        caption?: string | null;
    }

    export interface IStickerMessagePayload extends IMediaMessagePayload {
    }

    export class ImageMessagePayload
        extends MediaMessagePayload
        implements IImageMessagePayload {
        caption?: string | null;

        constructor(data: IImageMessagePayload) {
            super(data);
            this.caption = data.caption;
        }
    }

    export class StickerMessagePayload
        extends MediaMessagePayload
        implements IStickerMessagePayload {

        constructor(data: IStickerMessagePayload) {
            super(data);
        }
    }

    export interface IAudioMessagePayload extends IMediaMessagePayload { }

    export class AudioMessagePayload
        extends MediaMessagePayload
        implements IAudioMessagePayload {

        constructor(data: IAudioMessagePayload) {
            super(data);
        }

        public audio() {
            return new Audio(this.url);
        }
    }

    export interface IFileMessagePayload extends IMediaMessagePayload {
        caption?: string | null;
        name?: string | null;
    }

    export class FileMessagePayload
        extends MediaMessagePayload
        implements IMediaMessagePayload {
        readonly caption?: string | null;
        readonly name?: string | null;

        constructor(data: IFileMessagePayload) {
            super(data);
            this.caption = data.caption;
            this.name = data.name;
        }
    }

    export interface IInboundContactMessagePayload extends IPayload {
        contacts: IContact[];
    }

    export interface IContact {
        addresses: IAddress[];
        emails: IEmail[];
        urls: IUrl[];
        phones: IPhone[];
        ims: IIms;
        name: IName;
        organization: IOrganization;
    }

    export interface IAddress {
        city: string;
        country: string;
        countryCode: string;
        state: string;
        street: string;
        type: string;
        zip: string;
        formatted?: string;
    }

    export interface IEmail {
        value: string;
        type: string;
    }

    export interface IIms {
        service: string;
        userId: string;
    }

    export interface IName {
        first: string;
        middle: string;
        last: string;
        formatted: string;
        prefix: string;
        suffix: string;
    }

    export interface IOrganization {
        company: string;
        department: string;
        title: string;
    }

    export interface IPhone {
        value: string;
        type: string;
    }

    export interface IUrl {
        value: string;
        type: string;
    }

    export interface IInteractiveListResponseMessagePayload extends IPayload {
        title: string;
        reply: string;
        id: string | null;
        postbackText: string | null;
        description: string | null;
    }

    export interface InteractiveListMessage extends IPayload {
        title: string;
        body: string;
        buttons: IButton[];
        items: IInteractiveListItem[];
        id: string | null;
    }

    export interface IButton {
        title: string;
    }

    export interface IInteractiveListItem {
        title: string;
        options: IInteractiveListItemOption[];
    }

    export interface IInteractiveListItemOption {
        title: string;
        description: string | null;
        postbackText: string | null;
    }

    export interface ICustomer extends IPerson {
        customerId: Guid,
        isInternalUser: false;
    }

    export interface IPerson {
        id: Guid;
        name: string;
        isInternalUser: boolean;
    }

    export enum UserEventType {
        SandboxStart = 0,
        OptedIn = 1,
        OptedOut = 2,
    }
    export interface IUserEvent {
        phone: string;
        dateTime: Date;
        type: UserEventType;
    }

    export interface IMessageReaction {
        messageId: string;
        sender: IPerson,
        receiver?: IPerson,
        emoji: string,
        reactedAt: string;
    }

    export class MessageReaction implements Omit<IMessageReaction, "reactedAt"> {
        messageId: string;
        sender: IPerson;
        receiver?: IPerson;
        emoji: string;
        reactedAt: Date;

        constructor(data: IMessageReaction) {
            this.messageId = data.messageId,
                this.sender = data.sender,
                this.receiver = data.receiver,
                this.emoji = data.emoji,
                this.reactedAt = new Date(data.reactedAt);
        }
    }

    export interface IMessageEvent {
        messageId: string;
        destination: string;
        dateTime: string;
        type: MessageEventType;
        code: string | null;
        reason: string | null;
    }

    export class MessageEvent implements Omit<IMessageEvent, "dateTime"> {
        messageId: string;
        destination: string;
        dateTime: Date;
        type: MessageEventType;
        code: string | null;
        reason: string | null;

        constructor(data: IMessageEvent) {
            this.messageId = data.messageId;
            this.destination = data.destination;
            this.dateTime = new Date(data.dateTime);
            this.type = data.type;
            this.code = data.code;
            this.reason = data.reason;
        }

        toString() {
            return this.type != MessageEventType.Failed
                ? `${this.name} - ${this.dateTime.toLocaleString()}`
                : `${this.name} - ${this.dateTime.toLocaleString()} - ${this.code} - ${this.reason}`;
        }

        get name() {
            switch (this.type) {
                case MessageEventType.Enqueued: return "Na fila";
                case MessageEventType.Failed: return "Falha";
                case MessageEventType.Sent: return "Enviada";
                case MessageEventType.Delivered: return "Entregue";
                case MessageEventType.Read: return "Lida";
                default: return "";
            }
        }
    }

    export enum MessageEventType {
        Enqueued = 0,
        Failed = 1,
        Sent = 2,
        Delivered = 3,
        Read = 4,
    }

    export enum MenuMessageType {
        Button = 0,
        List = 1,
    }

    export interface IInteractiveButtonsResponsePayload extends IPayload {
        title: string;
        reply: string;
        id: string | null;
    }

    export interface IInteractiveButtonsMessagePayload extends IPayload {
        content: IInteractiveButtonsMessageContent;
        options: IInteractiveButtonsItemOption[];
    }

    export interface IInteractiveButtonsMessageContent {
        header: string | null;
        body: string;
        footer: string | null;
    }

    export interface IInteractiveButtonsItemOption {
        title: string;
        postbackText: string;
    }
}

export namespace DefaultMessages {
    export interface DefaultMessageType {
        type: Api.Domain.Enums.DefaultMessageType;
        description: string;
        variables: TextVariable[];
    }

    export interface TextVariable {
        description: string;
        variableName: string;
    }

    export const Types: DefaultMessageType[] = [
        {
            type: DefaultMessageType.When_Starting_Customer_Service,
            description: "Ao iniciar o atendimento",
            variables: [
                {
                    description: "Protocolo",
                    variableName: "{@Protocol}"
                }
            ]
        },
        {
            type: DefaultMessageType.When_Adding_New_Attendant,
            description: "Ao adicionar um ou mais atendentes",
            variables: [
                {
                    description: "Nomes dos Atendentes",
                    variableName: "{@AttedantsNames}"
                }
            ]
        },
        {
            type: DefaultMessageType.When_Changing_Attendant,
            description: "Ao mudar de atendente",
            variables: [
                {
                    description: "Nome do Atendente",
                    variableName: "{@AttedantsNames}"
                }
            ]
        },
        {
            type: DefaultMessageType.When_Removing_Attendant,
            description: "Ao remover um ou mais atendentes",
            variables: [
                {
                    description: "Nomes dos Atendentes",
                    variableName: "{@AttedantsNames}"
                }
            ]
        },
        {
            type: DefaultMessageType.When_Sending_To_Queue,
            description: "Ao enviar para fila",
            variables: [
                {
                    description: "Nome da Fila",
                    variableName: "{@ServiceQueueName}"
                }
            ]
        },
    ];
}

export namespace Report {

    export interface TypeItem<T> {
        type: T,
        name: string,
        icon?: string,
    }

    export interface ValidationTypeItem extends TypeItem<ValidationRuleType> {
        appliesTo?: FieldType[];
    }

    export const QuestionTypes: Dictionary<FieldType, TypeItem<FieldType>> = {} as unknown as Dictionary<FieldType, TypeItem<FieldType>>;
    QuestionTypes[FieldType.Text] = { type: FieldType.Text, name: 'Texto', icon: 'mdi-format-text' };
    QuestionTypes[FieldType.Number] = { type: FieldType.Number, name: 'Número', icon: 'mdi-numeric' };
    QuestionTypes[FieldType.Date] = { type: FieldType.Date, name: 'Data', icon: 'mdi-calendar' };
    QuestionTypes[FieldType.DateTime] = { type: FieldType.DateTime, name: 'Data e Hora', icon: 'mdi-calendar-clock' };
    QuestionTypes[FieldType.Time] = { type: FieldType.Time, name: 'Hora', icon: 'mdi-clock-outline' };

    export const ValidationRules: Dictionary<ValidationRuleType, ValidationTypeItem> = {} as unknown as Dictionary<ValidationRuleType, ValidationTypeItem>;
    ValidationRules[ValidationRuleType.Required] = { type: ValidationRuleType.Required, name: 'Obrigatório', icon: 'mdi-asterisk' };
    ValidationRules[ValidationRuleType.MinLength] = { type: ValidationRuleType.MinLength, name: 'Tamanho mínimo', icon: 'mdi-text-short' };
    ValidationRules[ValidationRuleType.MaxLength] = { type: ValidationRuleType.MaxLength, name: 'Tamanho máximo', icon: 'mdi-text-long' };
    ValidationRules[ValidationRuleType.GreaterThan] = { type: ValidationRuleType.GreaterThan, name: 'Maior que', icon: 'mdi-greater-than' };
    ValidationRules[ValidationRuleType.GreaterThanOrEqualTo] = { type: ValidationRuleType.GreaterThanOrEqualTo, name: 'Maior ou igual a', icon: 'mdi-greater-than-or-equal' };
    ValidationRules[ValidationRuleType.LessThan] = { type: ValidationRuleType.LessThan, name: 'Menor que', icon: 'mdi-less-than' };
    ValidationRules[ValidationRuleType.LessThanOrEqualTo] = { type: ValidationRuleType.LessThanOrEqualTo, name: 'Menor ou igual a', icon: 'mdi-less-than-or-equal' };

    ValidationRules[ValidationRuleType.MinLength].appliesTo = [FieldType.Text];
    ValidationRules[ValidationRuleType.MaxLength].appliesTo = [FieldType.Text];
    ValidationRules[ValidationRuleType.GreaterThan].appliesTo = [FieldType.Number];
    ValidationRules[ValidationRuleType.GreaterThanOrEqualTo].appliesTo = [FieldType.Number];
    ValidationRules[ValidationRuleType.LessThan].appliesTo = [FieldType.Number];
    ValidationRules[ValidationRuleType.LessThanOrEqualTo].appliesTo = [FieldType.Number];
}

export namespace Users {
    export interface TypeItem<T> {
        type: T,
        name: string,
    }

    export const UserLevels: Dictionary<UserLevel, TypeItem<UserLevel>> = {} as unknown as Dictionary<UserLevel, TypeItem<UserLevel>>;
    UserLevels[UserLevel.Bot] = { type: UserLevel.Bot, name: "Bot" };
    UserLevels[UserLevel.WaitingCompleteRegistration] = { type: UserLevel.WaitingCompleteRegistration, name: "Aguardando Confirmação" };
    UserLevels[UserLevel.None] = { type: UserLevel.None, name: "Nenhum" };
    UserLevels[UserLevel.Attendant] = { type: UserLevel.Attendant, name: "Atendente" };
    UserLevels[UserLevel.Manager] = { type: UserLevel.Manager, name: "Gerente" };
    UserLevels[UserLevel.Owner] = { type: UserLevel.Owner, name: "Proprietário" };
}
