import { AuthrorizationPaths, LoginActions, LogoutActions } from '@/store/modules/authorization/constants';
import { RouteConfig } from 'vue-router';

const apiAuthorizationRoutes: RouteConfig[] = [
    {
        path: AuthrorizationPaths.Login,
        component: () => import('@/features/authentication/login.vue'),
        props: { action: LoginActions.Login }
    },
    {
        path: AuthrorizationPaths.LoginFailed,
        component: () => import('@/features/authentication/login.vue'),
        props: { action: LoginActions.LoginFailed }
    },
    {
        path: AuthrorizationPaths.LoginCallback,
        component: () => import('@/features/authentication/login.vue'),
        props: { action: LoginActions.LoginCallback }
    },
    {
        path: AuthrorizationPaths.Profile,
        component: () => import('@/features/authentication/login.vue'),
        props: { action: LoginActions.Profile },
        meta: { authorize: true }
    },
    {
        path: AuthrorizationPaths.Register,
        component: () => import('@/features/authentication/login.vue'),
        props: { action: LoginActions.Register }
    },
    {
        path: AuthrorizationPaths.LogOut,
        component: () => import('@/features/authentication/logout.vue'),
        props: { action: LogoutActions.Logout }
    },
    {
        path: AuthrorizationPaths.LogOutCallback,
        component: () => import('@/features/authentication/logout.vue'),
        props: { action: LogoutActions.LogoutCallback }
    },
    {
        path: AuthrorizationPaths.LoggedOut,
        component: () => import('@/features/authentication/logout.vue'),
        props: { action: LogoutActions.LoggedOut }
    }
];

export default apiAuthorizationRoutes;
