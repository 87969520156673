import { Chat } from "@/typings/client.typings";
import { AuthorizationModule } from "../authorization";
import * as signalR from "@microsoft/signalr";
import { HubConnectionState } from "@microsoft/signalr";
import { Conversations } from "./index";
import { redirectToLogin } from "@/store/modules/authorization/utils";
import { IAttendantDto } from "@/clients/chat-client";
import { Guid } from "@/utils/guid";

export class WhatsAppService {
    private _connection: signalR.HubConnection;
    private emitStatus?: () => void;

    public onReceiveMessageAsync?: (conversationId: Guid, message: Chat.Message) => void;
    public onReceiveMessageEventAsync?: (conversationId: Guid, messageEvent: Chat.MessageEvent) => void;
    public onReceiveMessageReactionAsync?: (conversationId: Guid, messageReaction: Chat.IMessageReaction) => void;
    public onReceiveUserEventAsync?: (userEvent: Chat.IUserEvent) => void;
    public onCustomerUpdateEventAsync?: (id: Guid, name: string) => void;
    public onReceiveUserTyping?: (userName: string, conversationId: Guid, isTyping: boolean) => void;
    public onUpdateAttendants?: (conversationId: Guid, attendants: IAttendantDto[]) => void;
    public onUpdateServiceQueue?: (conversationId: Guid, serviceQueueId: Guid) => void;
    public onUpdateCustomerService?: (conversationId: Guid, customerServiceId: Guid) => void;
    public onUpdateNicknameOfCustomerEventAsync?: (id: Guid, nickname: string) => void;
    constructor() {
        this._connection = WhatsAppService.CreateConnection();
        this.MapEvents();
        const self = this;
        AuthorizationModule.subscribe(async (isAuth) =>
            isAuth ? await self.connect() : await self._connection.stop()
        );
    }

    private static CreateConnection(): signalR.HubConnection {
        const originalParseMessages = signalR.JsonHubProtocol.prototype.parseMessages;
        signalR.JsonHubProtocol.prototype.parseMessages = function (input, logger) {
            const messages = originalParseMessages.call(this, input, logger);
            return messages.map((message) => {
                if (message.type == signalR.MessageType.Invocation) {
                    (message.arguments as unknown) = message.arguments.map(arg => {
                        return JSON.parse(JSON.stringify(arg), Guid.guidReviver)
                    });
                }
                return message;
            });
        };

        const connection = new signalR.HubConnectionBuilder()
            .withUrl(`${process.env.VUE_APP_URL_SIGNALR}/hubs/whatsapp`, {
                accessTokenFactory: () => AuthorizationModule.accessToken,
            })
            .withAutomaticReconnect()
            .withHubProtocol(new signalR.JsonHubProtocol())
            .build();

        return connection;
    }

    private MapEvents(): void {
        const self = this;
        this._connection.on("ReceiveMessageAsync", (conversationId: Guid, message: Chat.IMessage) => {
            if (self.onReceiveMessageAsync != null) {
                self.onReceiveMessageAsync(conversationId, new Chat.Message(message));
            }
        });

        this._connection.on("ReceiveMessageEventAsync", (conversationId: Guid, messageEvent: Chat.IMessageEvent) => {
            if (self.onReceiveMessageEventAsync != null) {
                self.onReceiveMessageEventAsync(conversationId, new Chat.MessageEvent(messageEvent));
            }
        });

        this._connection.on("ReceiveMessageReactionAsync", (conversationId: Guid, messageReaction: Chat.IMessageReaction) => {
            if (self.onReceiveMessageReactionAsync != null) {
                self.onReceiveMessageReactionAsync(conversationId, messageReaction);
            }
        })

        this._connection.on("ReceiveUserEventAsync", (userEvent: Chat.IUserEvent) => {
            if (self.onReceiveUserEventAsync != null) {
                self.onReceiveUserEventAsync(userEvent);
            }
        });

        this._connection.on("CustomerUpdateEventAsync", (id: Guid, name: string) => {
            if (self.onCustomerUpdateEventAsync != null) {
                self.onCustomerUpdateEventAsync(id, name);
            }
        });

        this._connection.on("ReceiveUserTyping", (userName: string, conversationId: Guid, isTyping: boolean) => {
            if (self.onReceiveUserTyping != null) {
                self.onReceiveUserTyping(userName, conversationId, isTyping);
            }
        });

        this._connection.on("UpdateAttendants", (conversationId: Guid, attendants: IAttendantDto[]) => {
            if (self.onUpdateAttendants != null) {
                self.onUpdateAttendants(conversationId, attendants);
            }
        });

        this._connection.on("UpdateServiceQueue", (conversationId: Guid, serviceQueueId: Guid) => {
            if (self.onUpdateServiceQueue != null) {
                self.onUpdateServiceQueue(conversationId, serviceQueueId);
            }
        });

        this._connection.on("UpdateCustomerService", (conversationId: Guid, customerServiceId: Guid | null) => {
            if (self.onUpdateCustomerService != null) {
                self.onUpdateCustomerService(conversationId, customerServiceId ?? Guid.Empty);
            }
        });

        this._connection.on("UpdateNicknameOfCustomerEventAsync", (id: Guid, nickname: string) => {
            if (self.onUpdateNicknameOfCustomerEventAsync != null) {
                self.onUpdateNicknameOfCustomerEventAsync(id, nickname);
            }
        });
    }

    get status(): HubConnectionState {
        return this._connection.state;
    }

    async connect(): Promise<void> {
        try {
            if (this.status == HubConnectionState.Disconnected) {
                await this._connection.start();
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            if (!!e && e.statusCode == 401) {
                redirectToLogin();
            } else {
                console.log("onConnect", JSON.stringify(e));
            }
        } finally {
            if (this.emitStatus != null) {
                this.emitStatus();
            }
        }
    }

    bindConversationModule(conversationsModule: Conversations): void {
        this.onReceiveMessageAsync = (conversationId, message) => conversationsModule.addReceiveMessage({ conversationId, message });
        this.onReceiveMessageEventAsync = (conversationId, messageEvent) => conversationsModule.ADD_RECEIVE_MESSAGE_EVENT({ conversationId, messageEvent });
        this.onReceiveMessageReactionAsync = (conversationId, messageReaction) => conversationsModule.ADD_RECEIVE_MESSAGE_REACTION({ conversationId, messageReaction });
        this.onCustomerUpdateEventAsync = (id, name) => conversationsModule.CHANGE_CUSTOMER_NAME({ id, name });
        this.onReceiveUserTyping = (userName, conversationId, isTyping) => conversationsModule.UPDATE_CONVERSATION_USER_TYPING({ userName, conversationId, isTyping });
        this.onUpdateAttendants = (conversationId, attendants) => setTimeout(() => {
            conversationsModule.UPDATE_ATTENDANTS({ conversationId, attendants });
        }, 250);
        this.onUpdateServiceQueue = (conversationId, serviceQueueId) => conversationsModule.UPDATE_SERVICE_QUEUE({ conversationId, serviceQueueId });
        this.onUpdateCustomerService = (conversationId, customerServiceId) => conversationsModule.updateConversationCustomerService({ conversationId, customerServiceId });
        this.onUpdateNicknameOfCustomerEventAsync = (customerId, nickname) => conversationsModule.CHANGE_CUSTOMER_NICKNAME({ customerId, nickname });

        const self = this;
        this.emitStatus = () =>
            conversationsModule.SET_CHAT_STATUS(self._connection.state);

        this._connection.onclose(this.emitStatus);
        this._connection.onreconnected(this.emitStatus);
        this._connection.onreconnecting(this.emitStatus);
    }
}
