import { AxiosInstance, AxiosRequestConfig } from "axios";
import { BaseClient } from "./base-client";
import { Api } from "@/typings/api.typings";

export class QueueClient extends BaseClient {

    constructor(baseUrl?: string, instance?: AxiosInstance) {
        super(baseUrl, instance);
    }

    // Queries    

    async listQueues(query: Api.Companies.Queries.ListQueues.IListQueuesQuery): Promise<Api.Companies.Queries.ListQueues.IQueueDto[]> {
        let url = this.baseUrl + "/api/company/ListQueues?";
        url += this.getParams(query);
        
        const options : AxiosRequestConfig = {
            method: "GET",
            url: url,
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`
            }
        };

        return await this.sendRequest(options);
    }

    async listUsersOfQueue(query: Api.Companies.Queries.ListQueues.IListUsersQueueQuery): Promise<Api.Companies.Queries.ListQueues.IQueueUserDto[]> {
        let url = this.baseUrl + "/api/company/ListUsersOfQueue?";
        url += this.getParams(query);
        
        const options : AxiosRequestConfig = {
            method: "GET",
            url: url,
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`
            }
        };

        return await this.sendRequest(options);
    }

    async listQueuesOfUser(query: Api.Companies.Queries.ListQueuesOfUser.IListQueuesOfUser): Promise<Api.Companies.Queries.ListQueuesOfUser.IQueueUserDto[]> {
        let url = this.baseUrl + "/api/company/ListQueuesOfUser?";
        url += this.getParams(query);
        
        const options : AxiosRequestConfig = {
            method: "GET",
            url: url,
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`
            }
        };

        return await this.sendRequest(options);
    }

    // Commands

    async addQueue(command: Api.Companies.Commands.AddQueue.IAddQueueCommand) : Promise<void> {
        const url = this.baseUrl + "/api/company/AddQueue";
        
        const options : AxiosRequestConfig = {
            method: "POST",
            url: url,
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`
            },
            data: command            
        };

        return await this.sendRequest(options);
    }

    async removeQueue(command: Api.Companies.Commands.RemoveQueue.IRemoveQueueCommand) : Promise<void> {
        const url = this.baseUrl + "/api/company/RemoveQueue";
        
        const options : AxiosRequestConfig = {
            method: "POST",
            url: url,
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`
            },
            data: command            
        };

        return await this.sendRequest(options);
    }

    async updateQueue(command: Api.Companies.Commands.UpdateQueue.IUpdateQueueCommand) : Promise<void> {
        const url = this.baseUrl + "/api/company/UpdateQueue";
        
        const options : AxiosRequestConfig = {
            method: "PUT",
            url: url,
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`
            },
            data: command            
        };

        return await this.sendRequest(options);
    }

    async completeRegistration() : Promise<void> {
        // const url = this.baseUrl + "/api/user/completeregistration";
        
        // const options : AxiosRequestConfig = {
        //     method: "POST",
        //     url: url,
        //     headers: {
        //         "Accept": "application/json",
        //         "Authorization": `Bearer ${this.getAccessToken()}`
        //     }          
        // };

        //return await this.sendRequest(options);
    }
}