import notifyNewMessageSound from "@/assets/audio/notification.mp3";

export interface INotificationService {
    notifyNewMessage(): void;
}

export class NotificationService implements INotificationService {

    private readonly newMessageAudio = new Audio(notifyNewMessageSound);

    notifyNewMessage(): void {
        this.newMessageAudio.play();
    }

}

export default new NotificationService();