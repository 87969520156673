<template>
    <v-card :class="isMobile? 'mx-auto pt-3' : 'mx-auto'" width="400px" tile>

        <v-card-title> OPÇÃO </v-card-title>
        <v-card-text>
            <div class="d-block">
                <v-text-field
                    outlined
                    v-model="title"
                    :rules="rules.title"
                    label="Título da Opção"
                ></v-text-field>
                <v-textarea
                    outlined
                    auto-grow
                    rows="1"
                    v-model="text"
                    :rules="rules.text"
                    label="Texto da Opção"
                ></v-textarea>
                <v-autocomplete
                    outlined
                    clearable
                    v-model="selectedQueues"
                    label="Enviar para fila"
                    :items="queues"
                    item-text="name"
                    item-value="id"
                    return-object
                ></v-autocomplete>
                <v-textarea
                    outlined
                    auto-grow
                    rows="1"
                    v-model="messageText"
                    :rules="rules.messageText"
                    label="Mensagem da Opção"
                ></v-textarea>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="remove">
                Remover
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>


<script lang="ts">
import Vue from "vue";
import { Api } from "@/typings/api.typings";
import { Component, Prop } from "vue-property-decorator";
import * as validations from "@/utils/validations";
import { FormRules } from "@/components/action-controller.interfaces";

import ApiListQueues = Api.Companies.Queries.ListQueues;
import { Guid } from "@/utils/guid";

const valuesDefault = {
    title: '',
    text: '',
    messageText: '',
    selectedQueues: null
};

@Component
export default class MenuOptionBoxCard extends Vue {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Prop ({ required: true, default: () => ({ ...valuesDefault })}) values!: any;
    @Prop ({ required: true}) queues!: ApiListQueues.IQueueDto[]
    @Prop() remove: unknown;

    readonly id!: Guid | null;

    get isMobile() {
        return this.$vuetify.breakpoint.mobile;
    }

    get editing(): boolean {
        return this.id != null;
    }

    rules: FormRules = {
        $summary: [],
        title: [validations.requiredIf(!this.editing)],
    };

    get title(): string {
        return this.values.title;
    }
    set title(value: string) {
        this.values.title = value;
    }

    get text(): string {
        return this.values.text;
    }
    set text(value: string) {
        this.values.text = value;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get selectedQueues(): any {
        return this.values.selectedQueues;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    set selectedQueues(value: any) {
        this.values.selectedQueues = value;
    }

    get messageText(): string {
        return this.values.messageText;
    }
    set messageText(value: string) {
        this.values.messageText = value;
    }
}
</script>
