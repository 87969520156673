<template>
    <div>
        <v-overlay color="light" :value="loadingOverlayVisible && loadingVisible"></v-overlay>

        <v-snackbar v-model="loadingVisible" color="dark" :timeout="-1">
            <div class="d-flex flex-row">
                <span class="pa-2">{{ loadingMessage }}</span>
                <v-progress-circular class="pa-2 ml-auto" indeterminate color="primary"></v-progress-circular>
            </div>
        </v-snackbar>

        <v-snackbar v-model="snackbarVisible" :color="snackbarColor">
            <div class="d-flex flex-row">
                <span class="pa-2">{{ snackbarMessage }}</span>
                <v-btn class="pa-2 ml-auto" text @click="hideSnackbar">
                    <v-progress-circular
                        class="mr-2"
                        size="23"
                        width="2"
                        :value="percCloseTimeout"
                        color="white"
                    ></v-progress-circular>
                    Fechar
                </v-btn>
            </div>
        </v-snackbar>
    </div>
</template>

<script lang="ts">
/* eslint-disable */
import { Api } from "@/typings/api.typings";
import { Result } from "@/result";
import ResultError = Api.ResultError;
import Vue from "vue";
import Component from "vue-class-component";
import {
    IActionController,
    FormRules,
    ProcessSubmitOptions,
    ProcessActionOptions,
    ServerRuleValidation,
    FieldRules,
    ResponseMessages,
} from "./action-controller.interfaces";
import { nameof } from "@/utils/nameof";

const defaultMessages = {
    networkFailureMessage: "Não foi possível se comunicar com o servidor, verifique sua conexão com a internet.",
    successMessage: "Enviado com sucesso!",
    badRequestMessage: "Preenchimento inválido.",
    unauthorizedMessage: "Usuário sem permissão de acesso.",
    notFoundMessage: "O recurso que você está procurando não foi encontado.",
    internalErrorMessage: "Ops! Erro interno de servidor.",
    loadingMessage: "Enviando...",
    validationMessage: "Corrija os campos em destaque.",
};

const saveMessages = {
    loadingMessage: "Salvando...",
    successMessage: "Salvo com sucesso!",
};

const removeMessages = {
    loadingMessage: "Removendo...",
    successMessage: "Removido com sucesso!",
};

const reportMessages = {
    loadingMessage: "Processando relatório...",
    successMessage: "Relatório pronto para visualização!",
};

@Component
export default class ActionController extends Vue implements IActionController {
    /** Mensagens prontas para processos de salvar */
    saveMessages = saveMessages;

    /** Mensagens prontas para processos de remover */
    removeMessages = removeMessages;

    /** Mensagens prontas para processamento de relatórios */
    reportMessages = reportMessages;

    loadingVisible = false;

    snackbarVisible = false;

    loadingOverlayVisible = false;

    loadingMessage = "";

    snackbarMessage = "";

    snackbarColor = "";

    readonly timeout = 3000;
    readonly interval = this.timeout * 0.1;

    closeTimeout = this.timeout;
    funcTimeout: number | null = null;
    percCloseTimeout = 100;

    /**
     * Remove as validações de servidor de um formulário
     * @param rules Rules do formulário
     */
    public resetServerValidation(rules: FormRules): void {
        for (let fieldName in rules) {
            let server = this.getServerValidation(rules[fieldName]);

            if (server) server.validation = "";
        }
    }

    /**
     * Preenche um formulário com as mensagens de validação retornadas pelo servidor
     * @param formRules Rules do formulário
     * @param formErrors Mensagens de validação retornadas pelo servidor
     */
    public fillServerValidation(formRules: FormRules, formErrors: ResultError[]): void {
        let summaryErrors = [];

        for (const formError of formErrors) {
            let [fieldName, message] = formError.message.split(":");

            if (fieldName != null && message == null) {
                message = fieldName;
                fieldName = "";
            }

            fieldName = fieldName.trim().toCamelCase();
            message = message.trim();

            let fieldRuleName = fieldName;
            let validationMessage = this.getValidationText(message);

            if (fieldRuleName in formRules) {
                if (formRules[fieldRuleName] === null) {
                    formRules[fieldRuleName] = [];
                }

                let fieldRules = formRules[fieldRuleName];

                let serverValidation = this.getOrCreateServerValidation(fieldRules);
                if (serverValidation) serverValidation.validation = validationMessage;
                else summaryErrors.push(validationMessage);
            } else summaryErrors.push(validationMessage);
        }

        if (summaryErrors.length) {
            if (formRules["$summary"] === null) {
                formRules["$summary"] = [];
            }

            let summary = formRules["$summary"];

            if (summary) {
                let serverValidation = this.getOrCreateServerValidation(summary);
                serverValidation.validation = summaryErrors.join("\n");
            } else {
                console.error(
                    "Defina um $summary nas regras de validação do formulário para exibir erros de campos não visíveis na tela."
                );
            }
        }
    }

    /**
     * Processa o submit de um formulário.
     * Primeiro faz a validação do lado do cliente e somente se o formulário for válido ele é enviado ao servidor.
     * Um feedback de loading será exibido enquanto aguarda a resposta do servidor.
     * Caso o servidor retorne erros de validação elas serão exibidos no formulário automaticamente.
     * @param options
     */
    public async processSubmit<T>(options: ProcessSubmitOptions<T>): Promise<T> {
        if (!options) throw "Options deve ser informada";

        if (!options.form) throw "Options.form deve ser informado";

        if (!options.rules) throw "Options.rules deve ser informado";

        this.hideSnackbar();
        this.hideLoading();

        if (options.showLoadingOverlay === undefined || options.showLoadingOverlay === null)
            options.showLoadingOverlay = true;

        let formRules = options.rules;
        let form = options.form;

        if (formRules) {
            this.resetServerValidation(formRules);
            options.summary?.setRules(this.getServerValidation(formRules["$summary"]));
        }

        if (!form.validate()) {
            this.showFormValidationSnackbar(options);
            this.focusInvalidInput();
            return undefined as T;
        }

        try {
            this.showLoading(options);
            const ret = await options.action();
            this.showSuccessSnackbar(options);
            return ret;
        } catch (ex: any) {
            if (ex && ex.status && (ex.status == 400 || ex.status == 500)) {
                this.showFormValidationSnackbar(options);
                if (formRules && ex.response) {
                    const result = ex.response as Api.Result;
                    if (this.instanceOfResult(result)) {
                        this.fillServerValidation(formRules, result.errors);
                        options.summary?.setRules(this.getServerValidation(formRules["$summary"]));
                        form.validate();
                        this.focusInvalidInput();
                    }
                }
            } else {
                this.showErrorSnackbar(ex, options);
            }

            throw ex;
        } finally {
            this.hideLoading();
        }

        return undefined as T;
    }

    /**
     * Processa de ações que são enviadas ao servidor.
     * Um feedback de loading será exibido enquanto aguarda a resposta do servidor.
     * Caso o servidor retorne erros de validação elas serão exibidas em um snackbar.
     * @param options
     */
    public async processAction<T>(options: ProcessActionOptions<T>): Promise<T> {
        if (!options) throw "Options deve ser informada";

        if (!options.action) throw "Options.action deve ser informada";

        this.hideSnackbar();
        this.hideLoading();

        try {
            this.showLoading(options);

            const ret = await options.action();

            if (ret instanceof Result && ret.success === false) {
                this.showErrorSnackbar(ret, options);
                return ret;
            }

            this.showSuccessSnackbar(options);

            return ret;
        } catch (ex) {
            this.showErrorSnackbar(ex, options);
            return Promise.resolve(undefined as T);
        } finally {
            this.hideLoading();
        }
    }

    private showLoading(options: ProcessActionOptions) {
        let showLoading = options.showLoading !== false;
        let showLoadingOverlay = options.showLoadingOverlay === true;
        let loadingMessage = options.loadingMessage || defaultMessages.loadingMessage;

        this.loadingVisible = showLoading;
        this.loadingOverlayVisible = showLoading && showLoadingOverlay;
        this.loadingMessage = loadingMessage;
    }

    private hideLoading() {
        this.loadingVisible = false;
        this.loadingOverlayVisible = false;
    }

    hideSnackbar() {
        this.snackbarVisible = false;
        this.clearTimeout();
    }

    clearTimeout() {
        if (this.funcTimeout != null) {
            clearInterval(this.funcTimeout);
            this.funcTimeout = null;
        }
        this.closeTimeout = this.timeout;
        this.percCloseTimeout = 100;
    }

    getCaller() {
        const stack = new Error().stack;
        const stackLines = stack!.split("\n");

        // The first line is the current function, the second line is the function that called it,
        // and the third line is the function that called that function (the caller of the caller).
        const callerLine = stackLines[3];

        // Parse the caller line to get the relevant information
        return callerLine ? callerLine.trim() : null;
    }

    private setFuncTimeout(): void {
        if (this.snackbarVisible === false) {
            return;
        }

        if (this.funcTimeout != null) {
            console.error("setFuncTimeout: funcTimeout is not null");
            return;
        }

        this.funcTimeout = setInterval(
            (() => {
                this.closeTimeout -= this.interval;
                this.percCloseTimeout = Math.floor((this.closeTimeout / this.timeout) * 100);
                if (this.closeTimeout < 0) {
                    setTimeout(this.hideSnackbar.bind(this), this.interval);
                }
            }).bind(this),
            this.interval
        );
    }

    private showSuccessSnackbar(options: ProcessActionOptions) {
        let showSnackbar;
        if (typeof options.showSnackbar === "boolean") {
            showSnackbar = options.showSnackbar !== false;
        } else {
            showSnackbar = options.showSnackbar?.onSuccess !== false;
        }

        let message = options.successMessage || defaultMessages.successMessage;

        this.snackbarMessage = message;
        this.snackbarColor = "success";
        this.snackbarVisible = showSnackbar;
        this.setFuncTimeout();
    }

    private showErrorSnackbar(ex: any, options: ProcessActionOptions) {
        let showSnackbar;
        if (typeof options.showSnackbar === "boolean") {
            showSnackbar = options.showSnackbar !== false;
        } else {
            showSnackbar = options.showSnackbar?.onError !== false;
        }
        let message = this.getErrorMessage(ex, options);

        this.snackbarMessage = message!;
        this.snackbarColor = "error";
        this.snackbarVisible = showSnackbar;
        this.setFuncTimeout();
    }

    private showFormValidationSnackbar(options: ProcessActionOptions) {
        let showSnackbar = options.showSnackbar !== false;
        let message = options.validationMessage || defaultMessages.validationMessage;
        let color = "dark";

        if ("rules" in options) {
            const rules = options.rules as FormRules;

            for (const s of rules.$summary) {
                const result = s("");

                if (typeof result === "string" && !String.isNullOrWhiteSpace(result)) {
                    message = result;
                    color = "error";
                    break;
                }
            }
        }

        this.snackbarMessage = message;
        this.snackbarColor = color;
        this.snackbarVisible = showSnackbar;
        this.setFuncTimeout();
    }

    private createServerValidation(): ServerRuleValidation {
        function serverValidation() {
            if (serverValidation.validation) return serverValidation.validation;

            return true;
        }

        serverValidation.isServer = true;
        serverValidation.validation = "";

        return serverValidation;
    }

    private getServerValidation(rules: FieldRules): ServerRuleValidation {
        return rules.find((r: any) => r.isServer) as any;
    }

    private getOrCreateServerValidation(rules: FieldRules): ServerRuleValidation {
        let server = this.getServerValidation(rules);

        if (!server) {
            server = this.createServerValidation();
            rules.push(server);
        }

        return server;
    }

    private getValidationText(fieldErrors: any): string | null {
        if (typeof fieldErrors === "string") return fieldErrors;

        if (Array.isArray(fieldErrors) && fieldErrors.length) {
            let error = fieldErrors[0];

            if (typeof error === "string") return error;

            if ("text" in error) return error.text || null;
        }

        return null;
    }

    private instanceOfResult(object: any | null | undefined): object is Api.Result {
        return object != null && nameof<Api.Result>("success") in object && nameof<Api.Result>("errors") in object;
    }

    private getErrorMessage(ex: any, messages: ResponseMessages) {
        if (ex instanceof Result) {
            return ex.errors.map(m => m.toString()).join("; ");
        }
        if (this.instanceOfResult(ex.response)) {
            return (ex.response as Api.Result).errors.map(m => m.message.toString()).join("; ");
        }
        if (!ex.response || ex.response.status == 501 || ex.response.status == 502) {
            return (messages && messages.networkFailureMessage) || defaultMessages.networkFailureMessage;
        }

        if (!ex.response || (ex.response.status >= 200 && ex.response.status <= 299)) {
            return (messages && messages.successMessage) || defaultMessages.successMessage;
        }
        if (ex.response && ex.response.status == 400) {
            if (ex.response.data && ex.response.data.errors) {
                for (let fieldName in ex.response.data.errors) {
                    let fieldErrors = ex.response.data.errors[fieldName];
                    return this.getValidationText(fieldErrors);
                }
            }
            return (messages && messages.badRequestMessage) || defaultMessages.badRequestMessage;
        }

        if (ex.response && (ex.response.status == 401 || ex.response.status == 403)) {
            return (messages && messages.unauthorizedMessage) || defaultMessages.unauthorizedMessage;
        }

        if (ex.response && ex.response.status == 404) {
            return (messages && messages.notFoundMessage) || defaultMessages.notFoundMessage;
        }

        return (messages && messages.internalErrorMessage) || defaultMessages.internalErrorMessage;
    }

    private focusInvalidInput() {
        window.requestAnimationFrame(() => {
            let error = document.querySelector(".error--text");
            if (error) this.$vuetify.goTo(error as HTMLElement);
        });
    }
}
</script>
