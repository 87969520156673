import { Dictionary } from "@/typings/app"
import { Conversation } from "./conversation"

export interface IServiceQueue {
    name: string
    conversations: Dictionary<string, Conversation>
    hasLoadAllConversations: boolean
}

export class ServiceQueue implements IServiceQueue {
    public readonly name: string;
    public readonly conversations: Dictionary<string, Conversation>;
    public hasLoadAllConversations: boolean;

    constructor(data: IServiceQueue) {
        this.name = data.name;
        this.conversations = data.conversations
        this.hasLoadAllConversations = data.hasLoadAllConversations;
    }

}