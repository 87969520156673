<template>
    <v-dialog v-model="open" max-width="25vw" persistent :key="calls">
        <v-card>
            <v-card-title>
                {{ title }}
            </v-card-title>

            <v-card-text>
                <template v-for="(line, index) in text">
                    <span :key="`line-${index}`">{{ line }}</span>
                    <br :key="`br-${index}`" v-if="index < text.length - 1" />
                </template>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    v-for="(button, index) in buttons"
                    :key="index"
                    :color="button.color"
                    @click="execCallback(button.value)"
                    text
                >
                    <v-icon v-if="!String.isNullOrWhiteSpace(button.icon)" left>
                        {{ button.icon }}
                    </v-icon>
                    {{ button.text }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { DialogResult, MessageBoxButtons, IMessageBox, MessageBoxIcon } from "./message-box.interfaces";

interface IDialogButton {
    text: string;
    value: DialogResult;
    belongsTo: MessageBoxButtons[];
    icon?: string;
    color?: string;
}

const availableButtons: IDialogButton[] = [
    {
        text: "OK",
        value: DialogResult.OK,
        belongsTo: [MessageBoxButtons.OK, MessageBoxButtons.OKCancel],
    },
    {
        icon: "mdi-close",
        text: "Cancel",
        color: "error",
        value: DialogResult.Cancel,
        belongsTo: [
            MessageBoxButtons.OKCancel,
            MessageBoxButtons.AbortRetryIgnore,
            MessageBoxButtons.YesNoCancel,
            MessageBoxButtons.RetryCancel,
        ],
    },
    {
        icon: "mdi-alert",
        text: "Yes",
        color: "warning",
        value: DialogResult.Yes,
        belongsTo: [MessageBoxButtons.YesNo, MessageBoxButtons.YesNoCancel],
    },
    {
        icon: "mdi-close",
        text: "No",
        color: "error",
        value: DialogResult.No,
        belongsTo: [MessageBoxButtons.YesNo, MessageBoxButtons.YesNoCancel],
    },
    {
        icon: "mdi-reload",
        text: "Retry",
        color: "primary",
        value: DialogResult.Retry,
        belongsTo: [MessageBoxButtons.AbortRetryIgnore, MessageBoxButtons.RetryCancel],
    },
    {
        icon: "mdi-close",
        text: "Abort",
        color: "error",
        value: DialogResult.Abort,
        belongsTo: [MessageBoxButtons.AbortRetryIgnore],
    },
    {
        icon: "mdi-close",
        text: "Ignore",
        color: "error",
        value: DialogResult.Ignore,
        belongsTo: [MessageBoxButtons.AbortRetryIgnore],
    },
];

@Component
export default class MessageBox extends Vue implements IMessageBox {
    open = false;

    calls = 0;

    text: string[] = [];
    title = "";
    buttons: IDialogButton[] = [];
    icon: MessageBoxIcon = MessageBoxIcon.None;
    callback?: ActionT1<DialogResult>;

    show(options: {
        text: string;
        title?: string;
        buttons?: MessageBoxButtons;
        icon?: MessageBoxIcon;
        callback?: ActionT1<DialogResult>;
    }): void {
        this.calls++;
        this.text = options.text.split("\n");
        this.title = options.title || "";
        this.open = true;
        this.buttons = availableButtons.filter(b => b.belongsTo.includes(options.buttons || MessageBoxButtons.OK));
        this.icon = options.icon || MessageBoxIcon.None;
        this.callback = options.callback;
    }

    execCallback(value: DialogResult) {
        this.open = false;
        if (this.callback) {
            this.callback(value);
        }
    }
}
</script>
