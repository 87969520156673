import { RouteConfig, Location } from 'vue-router';

const NotFoundName = 'NotFound';
const ErrorName = 'Error';

export const NotFoundRoute: Location = {
    name: NotFoundName
};

export const ErrorRoute = (message: string): Location => ({
    name: ErrorName,
    params: {
        message
    }
});

export const UserCompleteRegistrationPath = '/users/complete-registration';
const UserCompleteRegistrationName = 'UserCompleteRegistration';
export const UserCompleteRegistrationRoute: Location = {
    name: UserCompleteRegistrationName
};

const userRoutes: RouteConfig[] = [
    {
        path: '*',
        redirect: "/page-not-found"
    },
    {
        path: "/page-not-found",
        name: NotFoundName,
        component: () => import('@/features/error/page404.vue'),
        props: (route) => route.params,
    },
    {
        path: '/error',
        name: ErrorName,
        component: () => import('@/features/error/error.vue'),
        props: (route) => route.params,
    }
];

export default userRoutes;
