declare global {
    interface StringConstructor {
        isNullOrWhiteSpace(value: string | null | undefined): value is null | undefined;
        isOnlyNumbers(value: string | null | undefined): boolean;
    }

    interface String {
        getHashCode(): number;
        toDashCase(): string;
        toPascalCase(): string;
        toCamelCase(): string;
    }
}

String.isNullOrWhiteSpace = (value: string | null | undefined): value is null | undefined => {
    return !(!!value && !!(value.trim()));
};

String.isOnlyNumbers = (value: string | null | undefined): boolean => {
    return !Number.isNaN(Number(value));
};

String.prototype.getHashCode = function () {
    if (this == null) {
        return 0;
    }

    let hash = 0;

    for (let i = 0; i < this.length; i++) {
        const char = this.charCodeAt(i);
        hash = (hash << 5) - hash + char;
    }

    return hash;
};

const casePattern = /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g;

String.prototype.toDashCase = function () {
    if (this == null || this.length <= 0)
        return "";

    return Array.from(this.matchAll(casePattern))?.join("-")?.toLowerCase() || "";
};

String.prototype.toPascalCase = function () {
    if (this == null || this.length <= 0)
        return "";

    const matches = Array.from(this.matchAll(casePattern));
    const values = [];

    for (let i = 0; i < matches.length; i++) {
        let value = matches[i].toString();
        value = value[0].toUpperCase() + (value.length > 1 ? value.slice(1) : "");
        values.push(value);
    }

    return values.join("");
};

String.prototype.toCamelCase = function () {
    if (this == null || this.length <= 0)
        return "";

    const value = this.toPascalCase();
    return value[0].toLowerCase() + (value.length > 1 ? value.slice(1) : "");
};

export { };
