import { Api } from '@/typings/api.typings';
import type { Dictionary } from '@/typings/app';
import type { Guid } from '@/utils/guid';
import type { RouteConfig, Location } from 'vue-router';

import MessengerType = Api.Domain.Enums.MessengerType;

export const MessageTemplateListName = 'MessageTemplateList';
const MessageTemplateInsertName: Dictionary<MessengerType, string> = {
    [MessengerType.None]: 'MessageTemplateNormalInsert',
    [MessengerType.WhatsApp]: 'MessageTemplateWhatsAppInsert',
    [MessengerType.Telegram]: 'MessageTemplateTelegramInsert',
    [MessengerType.FacebookMessenger]: 'MessageTemplateFacebookMessengerInsert',
    [MessengerType.Instagram]: 'MessageTemplateInstagramInsert',
};
const MessageTemplateEditName: Dictionary<MessengerType, string> = {
    [MessengerType.None]: 'MessageTemplateNormalEdit',
    [MessengerType.WhatsApp]: 'MessageTemplateWhatsAppEdit',
    [MessengerType.Telegram]: 'MessageTemplateTelegramEdit',
    [MessengerType.FacebookMessenger]: 'MessageTemplateFacebookMessengerEdit',
    [MessengerType.Instagram]: 'MessageTemplateInstagramEdit',
};

export const MessageTemplateListRoute: Location = {
    name: MessageTemplateListName
};

export const MessageTemplateInsertRoute = (messenger: MessengerType): Location => ({
    name: MessageTemplateInsertName[messenger]
});

export const MessageTemplateEditRoute = (id: Guid, messenger: MessengerType): Location => ({
    name: MessageTemplateEditName[messenger],
    params: {
        id: id.toString()
    }
});

const MessageTemplateRoutes: RouteConfig[] = [
    {
        path: '/message-templates',
        name: MessageTemplateListName,
        component: () => import('@/features/chat/parts/chat/parts/message-template/message-templates-list.vue'),
        meta: { authorize: true }
    },
];

const MessengerTypeKeys = Object
    .keys(MessengerType)
    .filter((v) => !Number.isNaN(Number(v)))
    .map(m => MessengerType[m as keyof typeof MessengerType]);

for (const messengerType of MessengerTypeKeys) {

    const messengerTypeValue = MessengerType[messengerType] as unknown as MessengerType;

    const name = messengerTypeValue === MessengerType.None
        ? 'normal'
        : messengerType.toString().toDashCase();

    MessageTemplateRoutes.push({
        path: `/message-templates/insert/${name}`,
        name: MessageTemplateInsertName[messengerTypeValue],
        component: () => import(`@/features/chat/parts/chat/parts/message-template/insert-edit/${name}/message-template-insert.vue`),
        meta: { authorize: true },
    });

    MessageTemplateRoutes.push({
        path: `/message-templates/edit/${name}/:id`,
        name: MessageTemplateEditName[messengerTypeValue],
        component: () => import(`@/features/chat/parts/chat/parts/message-template/insert-edit/${name}/message-template-edit.vue`),
        meta: { authorize: true },
        props: (route) => route.params
    });
}

export default MessageTemplateRoutes;
