import { AxiosInstance, AxiosRequestConfig } from "axios";
import { BaseClient } from "./base-client";

export namespace Api.Users.Commands.CompleteRegistration {
    export interface ICompleteRegistrationCommand {

    }
}

export namespace Api.Users.Commands.UpdateDisplayName {
    export interface IUpdateDisplayNameCommand {
        displayName: string
    }
}

export namespace Api.Users.Queries.CheckRegistration {
    export interface ICheckRegistrationQuery {

    }
}

export namespace Api.Users.Queries.GetDisplayName {
    export interface IGetDisplayNameQuery {

    }
}

import Users = Api.Users;

export class UserClient extends BaseClient {

    constructor(baseUrl?: string, instance?: AxiosInstance) {
        super(baseUrl, instance);
    }

    // Queries

    async checkRegistration(): Promise<boolean> {
        const url = this.baseUrl + "/api/user/checkregistration";
        
        const options : AxiosRequestConfig = {
            method: "GET",
            url: url,
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`
            }
        };

        return await this.sendRequest<boolean>(options);
    }

    async getDisplayName(): Promise<string> {
        const url = this.baseUrl + "/api/user/displayname";
        
        const options : AxiosRequestConfig = {
            method: "GET",
            url: url,
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`
            }
        };

        return await this.sendRequest<string>(options);
    }

    // Commands

    async completeRegistration() : Promise<void> {
        const url = this.baseUrl + "/api/user/completeregistration";
        
        const options : AxiosRequestConfig = {
            method: "POST",
            url: url,
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`
            }          
        };

        return await this.sendRequest(options);
    }

    async updateDisplayName(command: Users.Commands.UpdateDisplayName.IUpdateDisplayNameCommand) : Promise<void> {
        const url = this.baseUrl + "/api/user/displayname";
        
        const options : AxiosRequestConfig = {
            method: "PUT",
            url: url,
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${this.getAccessToken()}`
            },
            data: command       
        };

        return await this.sendRequest(options);
    }    
}