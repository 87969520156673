export interface IMessageBox {
    show(options: {
        text: string;
        title?: string;
        buttons?: MessageBoxButtons;
        icon?: MessageBoxIcon;
        callback?: ActionT1<DialogResult>;
    }): void;
}

export enum DialogResult {
    None,
    OK,
    Cancel,
    Yes,
    No,
    Abort,
    Retry,
    Ignore,
}

export enum MessageBoxButtons {
    OK,
    OKCancel,
    AbortRetryIgnore,
    YesNoCancel,
    YesNo,
    RetryCancel,
}

export enum MessageBoxIcon {
    None,
    Hand,
    Stop,
    Error,
    Question,
    Exclamation,
    Warning,
    Asterisk,
    Information,
}
