import { Guid } from "@/utils/guid";
import { Message } from ".";
import { Chat } from "@/typings/client.typings";
import { IConversationDto, IMessageDto } from "@/clients/chat-client";
import { CustomerService } from "./customer-service";
import { Dictionary } from "@/typings/app";

export class Conversation {
    public readonly id: Guid;
    public readonly messenger: Chat.MessengerType;
    public readonly person: Chat.ICustomer;
    public readonly messages: Message[];
    private readonly hashMessages: Dictionary<string, Message>;
    public lastReaction: Chat.MessageReaction | null;
    public hasLoadAllMessages: boolean;
    public unreadMessages: number;
    public usersTyping: string[] = [];
    public customerService: CustomerService | null = null;

    public replyMessage: Message | null = null;

    private _currentServiceQueueId: Guid | null = null;
    private _previousServiceQueueId: Guid | null = null;

    get isBeingServed(): boolean {
        return this.customerService != null;
    }

    get currentServiceQueueId() {
        return this._currentServiceQueueId ?? Guid.Empty;
    }

    get previousServiceQueueId() {
        return this._previousServiceQueueId ?? this.currentServiceQueueId;
    }

    constructor(conversation: IConversationDto) {
        this.id = conversation.id;
        this.person = conversation.customer;
        this.messenger = conversation.lastMessage.message.messenger;
        this.lastReaction = !conversation.lastReaction ? null : new Chat.MessageReaction(conversation.lastReaction);
        this.hasLoadAllMessages = false;
        this.unreadMessages = 0;
        this.messages = [];
        this.usersTyping = [];
        this.hashMessages = {};

        this.insertMessage({
            id: conversation.lastMessage.id,
            message: new Chat.Message(conversation.lastMessage.message),
            status: conversation.lastMessage.events.map(m => new Chat.MessageEvent(m)).sort((a, b) => a.type - b.type),
            reactions: conversation.lastMessage.reactions.map(m => new Chat.MessageReaction(m))
        });

        if (conversation.customerService != null) {
            this.customerService = new CustomerService(this, conversation.customerService);
            this._currentServiceQueueId = this.customerService.serviceQueueId;
            this._previousServiceQueueId = this.customerService.serviceQueueId;
        }
    }

    private static sortByDateAsc(x: Date, y: Date): number {
        return x.getTime() - y.getTime();
    }

    private static sortByDateDesc(x: Date, y: Date): number {
        return y.getTime() - x.getTime();
    }

    public updateQueue(id: Guid | null): void {
        this._previousServiceQueueId = this._currentServiceQueueId;
        this._currentServiceQueueId = id ?? Guid.Empty;
    }

    public hasMessage(messageId: string | Guid): boolean {
        return this.hashMessages[messageId.toString()] != null;
    }

    public getMessage(messageId: string | Guid): Message | null {
        return this.hashMessages[messageId.toString()] ?? null;
    }

    public insertMessage(message: Message): void {
        if (this.hasMessage(message.id.toString())) {
            return;
        }
        this.hashMessages[message.id.toString()] = message;
        this.messages.unshift(message);
    }

    public pushMessages(messages: IMessageDto[]): void {
        if (messages.length <= 0) {
            return;
        }

        const previousMessages: Message[] = [];

        for (const message of messages) {
            if (this.hasMessage(message.id)) {
                continue;
            }

            previousMessages.push({
                id: message.id,
                message: new Chat.Message(message.message),
                status: message.events.map(m => new Chat.MessageEvent(m)).sort((a, b) => a.type - b.type),
                reactions: message.reactions.map(m => new Chat.MessageReaction(m)),
            } as Message);
        }

        previousMessages.sort((x, y) => Conversation.sortByDateDesc(x.message.dateTime, y.message.dateTime));

        for (const message of previousMessages) {
            this.hashMessages[message.id.toString()] = message;
        }
        this.messages.push(...previousMessages);
    }
}
