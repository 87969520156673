import { Guid } from '@/utils/guid';
import { RouteConfig, Location } from 'vue-router';

const QueueListName = 'QueueList';
const QueueInsertName = 'QueueInsert'
const QueueEditName = 'QueueEdit'

export const QueueListRoute: Location = {
    name: QueueListName
}

export const QueueInsertRoute: Location = {
    name: QueueInsertName
}

export const QueueEditRoute = (id: Guid, name: string): Location => ({
    name: QueueEditName,
    params: {
        id: id.toString(),
        name
    }
});

const queueRoutes: RouteConfig[] = [
    {
        path: '/queues',
        name: QueueListName,
        component: () => import('@/features/registration/companies/queues/queues-list.vue'),
        meta: { authorize: true }
    },
    {
        path: '/queues/insert',
        name: QueueInsertName,
        component: () => import('@/features/registration/companies/queues/queue-insert-edit.vue'),
        meta: { authorize: true },
    }, 
    {
        path: '/queues/edit/:id',
        name: QueueEditName,
        component: () => import('@/features/registration/companies/queues/queue-insert-edit.vue'),
        meta: { authorize: true },
        props: (route) => route.params
    }
];

export default queueRoutes;