<template>
    <v-app>
        <v-app-bar app color="primary" dark clipped-left clipped-right>
            <v-app-bar-nav-icon @click.prevent="drawer = !drawer"></v-app-bar-nav-icon>

            <v-spacer></v-spacer>

            <v-btn
                v-if="isChatRoute && !isConnected"
                dark
                color="secondary"
                :loading="isReconnecting"
                :disabled="isReconnecting"
                @click="connect"
            >
                Conectar
            </v-btn>

            <v-menu offset-y :nudge-width="60" transition="scale-transition">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon dark v-bind="attrs" v-on="on">
                        <v-icon>mdi-account-circle</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <v-list>
                        <template v-if="!isAuthenticated">
                            <v-list-item :to="AuthrorizationPaths.Register">
                                <v-list-item-icon>
                                    <v-icon>mdi-account-plus</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title> Registrar </v-list-item-title>
                            </v-list-item>
                            <v-list-item :to="AuthrorizationPaths.Login">
                                <v-list-item-icon>
                                    <v-icon>mdi-login</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title> Entrar </v-list-item-title>
                            </v-list-item>
                        </template>
                        <template v-else>
                            <v-list-item :to="AuthrorizationPaths.Profile">
                                <v-list-item-icon>
                                    <v-icon>mdi-account-cog</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title> Gerenciar Conta </v-list-item-title>
                            </v-list-item>
                            <v-list-item :to="AuthrorizationPaths.LogOut">
                                <v-list-item-icon>
                                    <v-icon>mdi-exit-to-app</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title> Sair </v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-card>
            </v-menu>
        </v-app-bar>

        <v-navigation-drawer app v-model="drawer" clipped>
            <v-list nav>
                <v-list-item-group color="primary">
                    <v-list-item to="/chat">
                        <v-list-item-icon>
                            <v-icon>mdi-forum</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Conversas</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-group prepend-icon="mdi-plus-box-multiple-outline">
                        <template v-slot:activator>
                            <v-list-item-title>Cadastros</v-list-item-title>
                        </template>

                        <v-list-item to="/users">
                            <v-list-item-title>Usuários</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-account</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item to="/attendants">
                            <v-list-item-title>Atendentes</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-face-agent</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item to="/managers">
                            <v-list-item-title>Gerentes</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-account-tie</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item to="/schedule">
                            <v-list-item-title>Horários de Atendimento</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-store-clock</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item to="/queues">
                            <v-list-item-title>Filas</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-human-queue</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item to="/menus/insert">
                            <v-list-item-title>Menus</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-message</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item to="/reports">
                            <v-list-item-title>Relatos</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-comment-alert-outline</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item to="/message-templates">
                            <v-list-item-title>Modelos de Mensagens</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-message-reply-text-outline</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item to="/default-messages">
                            <v-list-item-title>Mensagens Padrões</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-message-reply-text-outline</v-icon>
                            </v-list-item-icon>
                        </v-list-item>

                        <v-list-item to="/services">
                            <v-list-item-title>Serviços de Api</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-api</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list-group>

                    <v-list-group v-if="userHasRoles(Roles.NewsSoft, Roles.Support)">
                        <template #prependIcon>
                            <v-icon>mdi-application-cog-outline</v-icon>
                        </template>
                        <template v-slot:activator>
                            <v-list-item-title>Gerenciamento</v-list-item-title>
                        </template>

                        <v-list-item :to="CompanyListRoute">
                            <v-list-item-title>Empresas</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-office-building-outline</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list-group>

                    <v-list-item to="/report">
                        <v-list-item-icon>
                            <v-icon>mdi-comment-alert-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Relatos</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

        <v-main class="main">
            <transition :name="transitionName">
                <router-view class="view" @close-drawers="drawer = false" />
            </transition>
        </v-main>

        <div id="drawer"></div>

        <action-controller ref="action"></action-controller>
        <message-box ref="messageBox"></message-box>
    </v-app>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { NavigationGuardNext, Route } from "vue-router";
import { AuthorizationModule } from "./store/modules/authorization";
import { ConversationsModule } from "./store/modules/conversations";
import { UserActivity } from "./store/modules/user-activity";
import ActionController from "@/components/action-controller.vue";
import { UserClient } from "./clients/user-client";
import { UserCompleteRegistrationRoute } from "./features/registration/users/user-routes";
import { ErrorRoute } from "./features/error/error-routes";
import { HubConnectionState } from "@microsoft/signalr";
import { ChatRouteName } from "@/features/chat/routes";
import { CompanyListRoute } from "@/features/app-manager/companies/company-routes";
import { Roles } from "@/constants";
import { AuthrorizationPaths } from "./store/modules/authorization/constants";
import MessageBox from "./components/message-box.vue";

@Component({
    components: {
        ActionController,
        MessageBox,
    },
})
export default class App extends Vue {
    drawer = false;
    selectedItem = 0;
    transitionName = "";
    users = [];
    subscribeId: number | null = null;

    AuthrorizationPaths = AuthrorizationPaths;

    CompanyListRoute = CompanyListRoute;

    get isAuthenticated() {
        return AuthorizationModule.isAuthenticated;
    }

    get isConnected(): boolean {
        return ConversationsModule.getChatStatus == HubConnectionState.Connected;
    }

    get isChatRoute(): boolean {
        return this.$route.name === ChatRouteName;
    }

    get isReconnecting(): boolean {
        return ConversationsModule.getChatStatus == HubConnectionState.Reconnecting;
    }

    async connect(): Promise<void> {
        await ConversationsModule.chatConnect();
        if (this.isConnected && this.$route.name !== "Chat") {
            this.$router.push({ name: "Chat" });
        }
    }

    beforeCreate(): void {
        ConversationsModule;
        AuthorizationModule;
        UserActivity;
    }

    async mounted(): Promise<void> {
        this.addActionControlerToRefs();
        this.configureRouterTransation();

        this.subscribeId = await AuthorizationModule.subscribe(this.executeAfterAuthenticated.bind(this));

        UserActivity.enableTracking();
    }

    beforeDestroy(): void {
        UserActivity.disableTracking();
    }

    async executeAfterAuthenticated(isAuth: boolean): Promise<void> {
        if (!isAuth) {
            return;
        }

        await AuthorizationModule.unsubscribe(Number(this.subscribeId));
        await this.checkUserRegistration();
        await AuthorizationModule.loadDisplayName();
    }

    async checkUserRegistration(): Promise<void> {
        try {
            const userClient = new UserClient();
            const result = await userClient.checkRegistration();
            if (result == false) {
                this.$router.push(UserCompleteRegistrationRoute);
            }
        } catch (ex) {
            this.$router.push(ErrorRoute("Erro ao verificar conta"));
        }
    }

    addActionControlerToRefs(): void {
        const vue = Vue.prototype;
        vue.$action = this.$refs.action;
        vue.$messageBox = this.$refs.messageBox;
    }

    configureRouterTransation(): void {
        this.$router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
            // Implements screen transition animation.
            // The direction depends on whether the new screen is a child or parent of the old screen.

            this.transitionName = to && from && to.fullPath.startsWith(from.fullPath) ? "slide-right" : "slide-left";

            next();
        });
    }

    Roles = Roles;
    userHasRoles = AuthorizationModule.userHasRoles;
}
</script>

<style>
/* Transition animation between pages */
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
    transition-duration: 0.5s;
    transition-property: height, opacity, transform;
    transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
}

.slide-left-enter,
.slide-right-leave-active {
    position: absolute;
    opacity: 0;
    transform: translate(60px, 0);
}

.slide-left-leave-active,
.slide-right-enter {
    position: absolute;
    opacity: 0;
    transform: translate(-60px, 0);
}
</style>
