import { WebStorageStateStore, UserManagerSettings, InMemoryWebStorage } from 'oidc-client';

export enum LogoutActions {
    Logout = 'logout',
    LoggedOut = 'logged-out',
    LogoutCallback = 'logout-callback',
}

export enum LoginActions {
    Login = 'login',
    LoginCallback = 'login-callback',
    LoginFailed = 'login-failed',
    Profile = 'profile',
    Register = 'register',
}

export const QueryParameterNames = {
    Message: 'message',
    ReturnUrl: 'returnUrl',
};

const prefix = '/authentication';

export const AuthrorizationPaths = {
    DefaultLoginRedirectPath: '/',
    ApiAuthorizationPrefix: prefix,
    Login: `${prefix}/${LoginActions.Login}`,
    LoginFailed: `${prefix}/${LoginActions.LoginFailed}`,
    LoginCallback: `${prefix}/${LoginActions.LoginCallback}`,
    Register: `${prefix}/${LoginActions.Register}`,
    Profile: `${prefix}/${LoginActions.Profile}`,
    LogOut: `${prefix}/${LogoutActions.Logout}`,
    LoggedOut: `${prefix}/${LogoutActions.LoggedOut}`,
    LogOutCallback: `${prefix}/${LogoutActions.LogoutCallback}`,
    IdentityRegisterPath: '/Account/Register',
    IdentityManagePath: '/Account/Manage'
};

const clientIds = new Object({
    android: 'news-soft-whatsapp-android-client',
    ios: 'news-soft-whatsapp-ios-client'
})

const navigator = {
    prepare: function () {
        return Promise.resolve(this);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    navigate: async function (params: any) {
        if (!params || !params.url) {
            return Promise.reject(new Error("No url provided"));
        }

        SafariViewController.isAvailable(function (available) {
            if (available) {
                SafariViewController.show(
                    {
                        url: params.url
                    },
                    function (result) {
                        console.log({ result });
                    },
                    function (error) {
                        console.log({ error });
                    }
                );
            } else {
                const inAppBrowser = cordova.InAppBrowser.open(params.url, '_blank', 'usewkwebview=yes');
                inAppBrowser.addEventListener("loadstop", (event) => {
                    if (event.url.indexOf("brcomnewssoftwhatsapp:") !== -1) {
                        inAppBrowser.close();
                        const uri = new URL(event.url);
                        const path = uri.pathname + uri.search

                        window.location.href = '/#' + path;
                    }
                })
            }
        })

        return Promise.resolve();
    },
    get url() {
        return window.location.href;
    }
}


const OpenIdClientSettingsBuilder: () => UserManagerSettings = () => {
    try {
        // eslint-disable-next-line no-prototype-builtins
        if (clientIds.hasOwnProperty(process.env.CORDOVA_PLATFORM)) {
            let origin = process.env.NODE_ENV === 'production' ? 'brcomnewssoftwhatsapp:' : 'https://localhost:8080';
            origin += process.env.NODE_ENV === 'production' ? '' : '/#'
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const clientId = (clientIds as any)[process.env.CORDOVA_PLATFORM];
            return {
                authority: process.env.VUE_APP_URL_AUTHORITY,
                client_id: clientId,
                redirect_uri: `${origin}${AuthrorizationPaths.LoginCallback}`,
                response_type: "code",
                scope: "openid email newssoft-whatsapp-api roles",
                post_logout_redirect_uri: `${origin}${AuthrorizationPaths.LogOutCallback}`,
                automaticSilentRenew: true,
                includeIdTokenInSilentRenew: true,

                redirectNavigator: navigator,

                userStore: new WebStorageStateStore({
                    prefix: clientId
                }),
                stateStore: new WebStorageStateStore({
                    prefix: clientId
                }),
            }

        }
        return {
            authority: process.env.VUE_APP_URL_AUTHORITY,
            client_id: 'news-soft-whatsapp-web-client',
            redirect_uri: window.location.origin + AuthrorizationPaths.LoginCallback,
            response_type: "code",
            scope: "openid email newssoft-whatsapp-api roles",
            post_logout_redirect_uri: window.location.origin + AuthrorizationPaths.LoginCallback,
            automaticSilentRenew: true,
            includeIdTokenInSilentRenew: true,

            userStore: new WebStorageStateStore({
                prefix: 'news-soft-whatsapp-web-client-user',
                store: new InMemoryWebStorage()
            }),
            stateStore: new WebStorageStateStore({
                prefix: 'news-soft-whatsapp-web-client-state'
            }),
        }
    } catch (error) {
        return {
            authority: process.env.VUE_APP_URL_AUTHORITY,
            client_id: 'news-soft-whatsapp-web-client',
            redirect_uri: window.location.origin + AuthrorizationPaths.LoginCallback,
            response_type: "code",
            scope: "openid email newssoft-whatsapp-api roles",
            post_logout_redirect_uri: window.location.origin + AuthrorizationPaths.LogOutCallback,
            automaticSilentRenew: true,
            includeIdTokenInSilentRenew: true,

            userStore: new WebStorageStateStore({
                prefix: 'news-soft-whatsapp-web-client-user'
            }),
            stateStore: new WebStorageStateStore({
                prefix: 'news-soft-whatsapp-web-client-state'
            }),
        }

    }
};

export const OpenIdClientSettings = OpenIdClientSettingsBuilder();

export const AuthMessages = {
    ProcessingLogin: 'Entrando...',
    ProcessingLoginCallback: 'Processando autenticação, por favor aguarde.',
    LoginFailed: 'Falha de login.',
    ProcessingLogout: 'Saindo...',
    LogoutNotInitiated: 'O processo de logout não foi iniciado na página.',
    ProcessingLogoutCallback: 'Saindo...',
    LoggedOut: 'Você saiu com sucesso!'
};
