import { Roles } from '@/constants';
import { Guid } from '@/utils/guid';
import { RouteConfig, Location } from 'vue-router';

export const DefaultPath = "/app-manager/api-services/:companyId";

const ApiServiceListName = 'ApiServiceList';
const ApiServiceInsertName = 'ApiServiceInsert';
const ApiServiceEditName = 'ApiServiceEdit';

export const ApiServiceListRoute = (companyId: Guid): Location => ({
    name: ApiServiceListName,
    params: {
        companyId: companyId.toString(),
    }
});

export const ApiServiceInsertRoute = (companyId: Guid): Location => ({
    name: ApiServiceInsertName,
    params: {
        companyId: companyId.toString(),
    }
});

export const ApiServiceEditRoute = (companyId: Guid, id: Guid): Location => ({
    name: ApiServiceEditName,
    params: {
        companyId: companyId.toString(),
        id: id.toString(),
    }
});

const apiServicesRoutes: RouteConfig[] = [
    {
        path: DefaultPath,
        name: ApiServiceListName,
        component: () => import('@/features/app-manager/api-services/api-service-list.vue'),
        meta: { authorize: true, roles: [Roles.NewsSoft, Roles.Support] },
        props: (route) => route.params
    },
    {
        path: `${DefaultPath}/insert`,
        name: ApiServiceInsertName,
        component: () => import('@/features/app-manager/api-services/api-service-insert-edit.vue'),
        meta: { authorize: true, roles: [Roles.NewsSoft, Roles.Support] },
        props: (route) => route.params
    },
    {
        path: `${DefaultPath}/edit/:id`,
        name: ApiServiceEditName,
        component: () => import('@/features/app-manager/api-services/api-service-insert-edit.vue'),
        meta: { authorize: true, roles: [Roles.NewsSoft, Roles.Support] },
        props: (route) => route.params
    },

];

export default apiServicesRoutes;
