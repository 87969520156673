import { Chat } from "@/typings/client.typings";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AuthorizationModule } from "../authorization";
import store from "@/store";
import {
    ChatClient,
    IAttendantDto,
    IConversationDto,
    ICustomerServiceDto,
    IMessageDto,
    ISeekMessageQuery,
} from "@/clients/chat-client";
import { QueueClient } from "@/clients/queue-client";
import { HubConnectionState } from "@microsoft/signalr";
import Vue from "vue";
import { WhatsAppService } from "./whats-app-service";
import notificationService from "./notification-service";
import router from "@/router";
import { ChatRouteName } from "@/features/chat/routes";
import { Api } from "@/typings/api.typings";
import ListQueuesOfUser = Api.Companies.Queries.ListQueuesOfUser;
import { Dictionary } from "@/typings/app";
import { Guid } from "@/utils/guid";
import { Conversation } from "./conversation";
import { ServiceQueue } from "./service-queue";
import { nameof } from "@/utils/nameof";
import { CustomerService } from "./customer-service";
import { Result, ResultWithValue } from "@/result";
import { AxiosError } from "axios";
import { notify } from "./conversation-events";
import { UserActivity } from "../user-activity";

export interface Message {
    id: Guid;
    message: Chat.Message;
    status: Chat.MessageEvent[];
    reactions: Chat.MessageReaction[];
}

const whatsAppService = new WhatsAppService();

interface AddMessagesToConversation {
    conversationId: Guid;
    messages: IMessageDto[];
    hasLoadAllMessages: boolean;
}

interface ISetChat {
    chat: Conversation;
    message: Chat.Message;
}

export interface ISendMessage {
    conversationId: Guid;
    messagePayload: ISendMessagePayload;
}

export interface ISendMessagePayload {
    type: Chat.MessageType;
    payload: Chat.IPayload;
}

export interface IAddReceiveMessage {
    conversationId: Guid;
    message: Chat.Message;
}

@Module({ dynamic: true, store, name: "conversations" })
export class Conversations extends VuexModule {
    private chats: Dictionary<string, Conversation> = {};
    private queues: Dictionary<string, ServiceQueue> = {};

    private conversationSelectedId: Guid | null = null;

    private loadingConversations = false;
    private chatStatus = HubConnectionState.Disconnected;

    private queueIdSelected: Guid | null = null;

    velocities: Dictionary<string, number> = {};

    private readonly playbackRates = [1, 1.5, 2];

    get getVelocityByCustomerId() {
        return (customerId: string) => {
            return this.playbackRates[this.velocities[customerId] ?? 0];
        };
    }

    @Mutation
    CHANGE_VELOCITY_OF_CUSTOMER(customerId: string) {
        let currentIndex = this.velocities[customerId] ?? 0;
        currentIndex = (currentIndex + 1) % this.playbackRates.length;
        Vue.set(this.velocities, customerId, currentIndex);
        notify("onVelocityOfAudioChanged", customerId);
    }

    get getConversationById() {
        return (conversationId: Guid | string): Conversation | null => {
            if (conversationId == null) {
                return null;
            }

            let value: Guid;
            if (typeof conversationId === "string") {
                value = Guid.parse(conversationId);
            } else if (conversationId instanceof Guid) {
                value = conversationId;
            } else {
                return null;
            }

            const chat = this.chats[value.toString()];

            return chat ?? null;
        };
    }

    get getChatStatus(): HubConnectionState {
        return this.chatStatus;
    }

    get getConversationSelected(): Conversation | null {
        const index = this.conversationSelectedId;

        if (index == null) {
            return null;
        }

        const chat = this.chats[index.toString()];
        return chat ?? null;
    }

    get getConversationIdSelected(): Guid | null {
        return this.conversationSelectedId;
    }

    get getChats(): Conversation[] {
        return Conversations.ListChats(this.chats);
    }

    private static ListCustomerServices(source: Dictionary<string, Conversation>): Conversation[] {
        return Object.values(source).sort((a, b) =>
            Conversations.sortByDateAsc(
                a.customerService?.startedAt ?? new Date(0),
                b.customerService?.startedAt ?? new Date(0)
            )
        );
    }

    private static ListChats(source: Dictionary<string, Conversation>): Conversation[] {
        return Object.values(source).sort((a, b) =>
            Conversations.sortByDateDesc(a.messages[0].message.dateTime, b.messages[0].message.dateTime)
        );
    }

    get listChatsByServiceQueueId() {
        return (serviceQueueId: Guid) => {
            const queue = this.queues[serviceQueueId.toString()];

            if (queue && queue.conversations) {
                if (Guid.Empty.equals(serviceQueueId)) {
                    return Conversations.ListChats(queue.conversations);
                } else {
                    return Conversations.ListCustomerServices(queue.conversations);
                }
            }
            return [];
        };
    }

    get getLoadingConversations() {
        return this.loadingConversations;
    }

    get getHasLoadAllConversations() {
        return (serviceQueueId: Guid): boolean => {
            return this.queues[serviceQueueId.toString()].hasLoadAllConversations;
        };
    }

    get getQueues(): {
        id: string;
        name: string;
    }[] {
        return Object.entries(this.queues).map(m => ({
            id: m[0],
            name: m[1].name,
        }));
    }

    get getQueueIdSelected(): Guid {
        return this.queueIdSelected ?? Guid.Empty;
    }

    get getLastMessageByConversationId(): (conversationId: Guid) => Message | null {
        return (conversationId: Guid) => {
            const chat = this.getConversationById(conversationId);
            return chat?.messages[0] ?? null;
        };
    }

    private static sortByDateAsc(x: Date, y: Date): number {
        return x.getTime() - y.getTime();
    }

    private static sortByDateDesc(x: Date, y: Date): number {
        return y.getTime() - x.getTime();
    }

    @Mutation
    public SET_CHAT_STATUS(status: HubConnectionState): void {
        if (status != null) {
            this.chatStatus = status;
        }
    }

    @Mutation
    public SET_LOADING_CONVERSATIONS(value: boolean): void {
        // eslint-disable-next-line no-extra-boolean-cast
        this.loadingConversations = !!value ? true : false;
    }

    @Mutation
    public SET_CONVERSATION(conversationId: Guid | null): void {
        const isToNotify = conversationId != null && this.conversationSelectedId != conversationId;
        this.conversationSelectedId = conversationId;
        if (isToNotify) {
            notify("onConversationSelectedChanged");
        }
    }

    @Mutation
    public CLEAN_UNREAD_MESSAGES_OF_CONVERSATION(conversationId: Guid): void {
        if (Guid.isGuid(conversationId) && !Guid.Empty.equals(conversationId)) {
            const conversation = this.chats[conversationId.toString()];
            if (conversation != null) {
                conversation.unreadMessages = 0;
            }
        }
    }

    @Mutation
    public SET_CHAT(value: ISetChat): void {
        if (value == null) {
            return;
        }

        try {
            const { chat, message } = value;

            const id = chat.id.toString();

            if (this.chats[id] === undefined) {
                Vue.set(this.chats, id, chat);
                Vue.set(this.queues[chat.currentServiceQueueId.toString()].conversations, id, chat);
            }

            if (this.chats[id].hasMessage(message.id)) {
                return;
            }

            const toInsert = { id: value.message.id, message: value.message, status: [], reactions: [] } as Message;

            this.chats[id].insertMessage(toInsert);

            notify("onConversationMessageAdded", { conversationId: chat.id, newMessages: true });

            if (
                message.sender.isInternalUser === false
                && (!this.conversationSelectedId?.equals(id)
                    || router.currentRoute.name !== ChatRouteName
                    || UserActivity.UserIsActive === false)
            ) {
                notificationService.notifyNewMessage();
            }
        } catch (error) {
            console.log("error", { error });
        }
    }

    @Mutation
    public CHANGE_CUSTOMER_NAME(value: { id: Guid; name: string; }): void {
        const conversations = Object.values(this.chats).filter(f => value.id.equals(f.person.id));

        for (const conversation of conversations) {
            Vue.set(conversation.person, nameof<Chat.IPerson>("name"), value.name);
        }
    }

    @Mutation
    public CHANGE_CUSTOMER_NICKNAME(value: { customerId: Guid; nickname: string; }): void {
        const { customerId, nickname } = value;

        const chat = Object.values(this.chats).find(f => f.person.customerId.equals(customerId));

        if (chat == null) {
            return;
        }

        Vue.set(chat.person, nameof<Chat.ICustomer>("name"), nickname);
    }

    @Mutation
    public UPDATE_CONVERSATION_CUSTOMER_SERVICE(value: {
        conversationId: Guid;
        customerService: ICustomerServiceDto | null;
    }): void {
        try {
            if (value === undefined) {
                return;
            }

            const chat = this.chats[value.conversationId.toString()];

            if (chat == null) {
                return;
            }

            if (value.customerService == null) {
                ConversationsModule.UPDATE_SERVICE_QUEUE({
                    conversationId: value.conversationId,
                    serviceQueueId: Guid.Empty,
                });
            } else if (value.customerService.serviceQueueId != null) {
                ConversationsModule.UPDATE_SERVICE_QUEUE({
                    conversationId: value.conversationId,
                    serviceQueueId: value.customerService.serviceQueueId,
                });
            }

            let customerService: CustomerService | null = null;
            if (value.customerService != null) {
                customerService = new CustomerService(chat, value.customerService);
            }

            Vue.set(chat, nameof<Conversation>("customerService"), customerService);
            if (customerService != null) {
                notify("onConversationCustomerServiceAdded", customerService);
            }
        } catch (error) {
            console.error("UPDATE_CONVERSATION_CUSTOMER_SERVICE", error);
        }
    }

    @Action({ commit: "UPDATE_CONVERSATION_CUSTOMER_SERVICE" })
    public async updateConversationCustomerService(value: {
        conversationId: Guid;
        customerServiceId: Guid;
    }): Promise<{ conversationId: Guid; customerService: ICustomerServiceDto | null; } | undefined> {
        try {
            const chat = this.chats[value.conversationId.toString()];

            if (chat == null) {
                return;
            }

            if (value.customerServiceId.isEmpty()) {
                return { conversationId: value.conversationId, customerService: null };
            }

            const client = new ChatClient();
            const customerServiceDto = await client.getCustomerServiceById({
                customerServiceId: value.customerServiceId,
            });
            return { conversationId: value.conversationId, customerService: customerServiceDto };
        } catch (error) {
            console.error("updateConversationCustomerService", error);
        }
    }

    @Mutation
    public UPDATE_CONVERSATION_USER_TYPING(value: { userName: string; conversationId: Guid; isTyping: boolean; }): void {
        const chat = this.chats[value.conversationId.toString()];
        if (chat == null) {
            return;
        }

        const users = chat.usersTyping;

        let userIndex = users.findIndex(f => f == value.userName);

        if (userIndex < 0 && value.isTyping) {
            userIndex = users.length;
        }

        if (userIndex >= 0) {
            if (value.isTyping) {
                Vue.set(users, userIndex, value.userName);
            } else {
                Vue.delete(users, userIndex);
            }
        }
    }

    @Mutation
    public UPDATE_ATTENDANTS(value: { conversationId: Guid; attendants: IAttendantDto[]; }): void {
        const chat = this.chats[value.conversationId.toString()];
        if (chat == null) {
            return;
        }

        if (chat.customerService == null) {
            return;
        }

        Vue.set(chat.customerService, nameof<CustomerService>("attendants"), value.attendants);
    }

    @Mutation
    public UPDATE_SERVICE_QUEUE(value: { conversationId: Guid; serviceQueueId: Guid; }): void {
        try {
            const chat = this.chats[value.conversationId.toString()];
            if (chat == null) {
                return;
            }

            const fromServiceQueueId = chat.previousServiceQueueId;
            const toServiceQueueId = Guid.isGuid(value.serviceQueueId)
                ? Guid.parse(value.serviceQueueId.toString())
                : Guid.Empty;

            const toServiceQueue = this.queues[toServiceQueueId.toString()];
            const fromServiceQueue = this.queues[fromServiceQueueId.toString()];

            chat.updateQueue(toServiceQueueId);

            if (chat.customerService != null) {
                Vue.set(chat.customerService, nameof<CustomerService>("serviceQueueId"), toServiceQueueId);
            }

            if (fromServiceQueue != null) {
                Vue.delete(fromServiceQueue.conversations, value.conversationId.toString());
            }

            if (toServiceQueue != null) {
                Vue.set(toServiceQueue.conversations, value.conversationId.toString(), chat);
            }

            if (this.conversationSelectedId?.equals(value.conversationId) ?? false) {
                this.queueIdSelected = toServiceQueueId;
            }
        } catch (error) {
            console.error("UPDATE_SERVICE_QUEUE", error);
        }
    }

    @Action({ commit: "SET_CHAT" })
    public async addReceiveMessage(payload: IAddReceiveMessage): Promise<ISetChat | null> {
        if (Guid.isGuid(payload.conversationId) == false) {
            return null;
        }

        const conversationId = payload.conversationId as Guid;
        const message = payload.message;

        let chat = this.chats[conversationId.toString()];

        if (chat == undefined) {
            const chatClient = new ChatClient();
            const data = await chatClient.getConversationById({ conversationId });
            chat = new Conversation(data);
        }

        if (
            document.hasFocus() === false ||
            UserActivity.UserIsActive === false ||
            router.currentRoute.name !== ChatRouteName ||
            !this.conversationSelectedId?.equals(conversationId) ||
            chat.unreadMessages > 0
        ) {
            chat.unreadMessages += 1;
        }

        return {
            chat,
            message,
        };
    }

    @Mutation
    public ADD_RECEIVE_MESSAGE_EVENT(value: { conversationId: Guid; messageEvent: Chat.MessageEvent; }): void {
        const { conversationId, messageEvent } = value;

        const chat = this.chats[conversationId.toString()];

        if (chat == null) {
            return;
        }

        const message = chat.getMessage(messageEvent.messageId);
        if (message != null) {
            message.status.push(messageEvent);
            message.status = message.status.sort((a, b) => a.type - b.type);
        }
    }

    @Mutation
    public ADD_RECEIVE_MESSAGE_REACTION(value: { conversationId: Guid; messageReaction: Chat.IMessageReaction; }): void {
        const { conversationId, messageReaction } = value;

        const chat = this.chats[conversationId.toString()];

        if (chat == null) {
            return;
        }

        const message = chat.getMessage(messageReaction.messageId.toString());

        let reaction: Chat.MessageReaction | null | undefined = null;

        if (message == null) {
            reaction = new Chat.MessageReaction(messageReaction);
            if (chat.lastReaction == null || reaction.reactedAt > chat.lastReaction.reactedAt) {
                chat.lastReaction = reaction;
            }
            return;
        }

        reaction = message.reactions.find(
            reaction => reaction.sender.id.toString() === messageReaction.sender.id.toString()
        );

        if (reaction == null) {
            reaction = new Chat.MessageReaction(messageReaction);
            message.reactions.push(reaction);
        } else {
            reaction.emoji = messageReaction.emoji;
            reaction.reactedAt = new Date(messageReaction.reactedAt);
        }

        if (chat.lastReaction == null || reaction.reactedAt > chat.lastReaction.reactedAt) {
            chat.lastReaction = reaction;
        }
    }

    @Mutation
    public ADD_CONVERSATION(value: { conversations: IConversationDto[]; serviceQueueId: Guid; }): void {
        if (value.conversations == null || value.conversations.length <= 0) {
            return;
        }

        for (const conversation of value.conversations) {
            const conversationFinded = this.chats[conversation.id.toString()];
            if (conversationFinded == null) {
                const chat = new Conversation(conversation);

                // Ensure the 'conversations' property is initialized as an empty object
                if (!this.queues[value.serviceQueueId.toString()].conversations) {
                    Vue.set(this.queues[value.serviceQueueId.toString()], nameof<ServiceQueue>("conversations"), {});
                }

                Vue.set(this.chats, conversation.id.toString(), chat);
                const queue = this.queues[value.serviceQueueId.toString()];
                Vue.set(queue.conversations, conversation.id.toString(), chat);
            }
        }
    }

    @Mutation
    public ADD_MESSAGES_TO_CONVERSATION(param: AddMessagesToConversation): void {
        if (param == null || param.conversationId == null || Guid.Empty.equals(param.conversationId)) {
            return;
        }

        const conversation = this.chats[param.conversationId.toString()];
        if (conversation == null) {
            return;
        }

        conversation.hasLoadAllMessages = param.hasLoadAllMessages;

        if (Array.isArray(param.messages) === false || param.messages.length <= 0) {
            return;
        }

        conversation.pushMessages(param.messages);

        notify("onConversationMessageAdded", { conversationId: conversation.id, newMessages: false });
    }

    @Mutation
    public HAS_LOAD_ALL_CONVERSATIONS(serviceQueueId: Guid): void {
        this.queues[serviceQueueId.toString()].hasLoadAllConversations = true;
    }

    @Mutation
    public SET_QUEUES(queueDtos: ListQueuesOfUser.IQueueUserDto[]) {
        for (const queueDto of queueDtos) {
            const queue = this.queues[queueDto.id.toString()];
            if (queue == null) {
                Vue.set(
                    this.queues,
                    queueDto.id.toString(),
                    new ServiceQueue({
                        name: queueDto.name,
                        conversations: {},
                        hasLoadAllConversations: false,
                    })
                );
            } else {
                Vue.set(queue, nameof<ServiceQueue>("name"), queueDto.name);
            }
        }

        for (const entry of Object.entries(this.queues)) {
            if (queueDtos.some(s => entry[0] == s.id.toString()) == false) {
                delete this.queues[entry[0]];
            }
        }

        for (const chat of Object.values(this.chats)) {
            const queue = this.queues[chat.currentServiceQueueId.toString()];
            if (queue == null) {
                this.queues[Guid.Empty.toString()].conversations[chat.id.toString()] = chat;
            } else {
                queue.conversations[chat.id.toString()] = chat;
            }
        }
    }

    @Mutation
    public SET_QUEUE_ID_SELECTED(serviceQueueId: Guid | null) {
        this.queueIdSelected = serviceQueueId;
    }

    @Action
    public async sendMessage(value: ISendMessage): Promise<boolean> {
        const conversation = this.getConversationById(value.conversationId);
        if (conversation == null) {
            return false;
        }

        if (AuthorizationModule.isAuthenticated == false) {
            return false;
        }

        const message = new Chat.Message({
            flow: Chat.MessageFlow.Outbound,
            type: value.messagePayload.type,
            dateTime: new Date().toISOString(),
            receiver: conversation.person,
            messenger: conversation.messenger,
            sender: {
                id: AuthorizationModule.userId,
                name: AuthorizationModule.getDisplayName,
                isInternalUser: true,
            },
            payload: value.messagePayload.payload as Chat.ITextMessagePayload,
            contextId: conversation.replyMessage?.message.id.toString(),
        } as unknown as Chat.IMessagePayload<Chat.ITextMessagePayload>);

        const client = new ChatClient();

        await client.sendMessage({
            conversationId: value.conversationId,
            message: message as unknown as Chat.IMessage,
        });

        this.SET_REPLY_MESSAGE({
            conversationId: conversation.id,
            message: null,
        });

        return true;
    }

    @Action
    public async listConversation(serviceQueueId: Guid): Promise<void> {
        const id = serviceQueueId.toString();
        if (this.loadingConversations == true || this.queues[id].hasLoadAllConversations == true) {
            return;
        }

        try {
            this.SET_LOADING_CONVERSATIONS(true);

            const chatsLength = Object.values(this.queues[id].conversations).length;

            const chatClient = new ChatClient();
            const conversations = await chatClient.listConversation({
                page: Math.trunc(chatsLength / 10) + 1,
                size: 12,
                serviceQueueId: serviceQueueId,
            });

            if (conversations.length < 12) {
                this.HAS_LOAD_ALL_CONVERSATIONS(serviceQueueId);
            }

            const addConversationObj = {
                conversations: conversations,
                serviceQueueId: serviceQueueId,
            };

            this.ADD_CONVERSATION(addConversationObj);
        } finally {
            this.SET_LOADING_CONVERSATIONS(false);
        }
    }

    @Action
    public async listMessages(conversationId: Guid | string): Promise<void> {
        try {
            const conversation = this.getConversationById(conversationId.toString());
            if (conversation == null) {
                return;
            } else if (conversation.hasLoadAllMessages == true) {
                return;
            }

            conversationId = Guid.parse(conversationId.toString());

            const pageSize = 24;
            const messenger = conversation.messenger;
            const chatClient = new ChatClient();
            let hasLoadAllMessages = false;

            const messages = await chatClient.listMessages({
                conversationId: conversationId,
                messenger,
                page: Math.trunc(conversation.messages.length / pageSize) + 1,
                size: pageSize,
            });

            hasLoadAllMessages = messages.length < pageSize;

            this.ADD_MESSAGES_TO_CONVERSATION({
                conversationId,
                messages,
                hasLoadAllMessages,
            });
        } catch (error) {
            console.error("conversations.listMessages", error);
            throw error;
        }
    }

    @Action
    public async seekMessage(query: ISeekMessageQuery): Promise<ResultWithValue<Message>> {
        const { conversationId, lastMessageId, messageId } = query;
        try {
            const seek = (conversation: Conversation, messageId: string | Guid) => {
                const messageToSeek = conversation.getMessage(messageId);
                if (messageToSeek != null) {
                    return Result.ok<Message>(messageToSeek);
                } else {
                    return Result.fail<Message>(["Mensagem não encontrada"]);
                }
            };

            const conversation = this.getConversationById(conversationId.toString());
            if (conversation == null) {
                return Result.fail<Message>(["Conversa não encontrada"]);
            } else if (conversation.hasLoadAllMessages == true) {
                return seek(conversation, messageId);
            } else {
                const result = seek(conversation, messageId);
                if (result.success) {
                    return result;
                }
            }

            const chatClient = new ChatClient();

            let messages = await chatClient.seekMessage({
                conversationId,
                lastMessageId,
                messageId,
            });

            if (conversation.messages.length > 0) {
                const ids: string[] = <string[]> (
                    conversation.messages.map(m => m.message.id?.toString()).filter(f => f != null && f != "")
                );
                messages = messages.filter(f => f.message.id != null && !ids.includes(f.message.id.toString()));
            }

            this.ADD_MESSAGES_TO_CONVERSATION({
                conversationId,
                messages,
                hasLoadAllMessages: false,
            });

            return seek(conversation, messageId);
        } catch (error) {
            if (error instanceof AxiosError) {
                if (error.status == 404) {
                    console.log("error", JSON.stringify(error));
                }
            }
            console.error("conversations.listMessages", error);
            throw error;
        }
    }

    @Action({ commit: "SET_CHAT_STATUS" })
    public async chatConnect(): Promise<void> {
        this.SET_CHAT_STATUS(HubConnectionState.Reconnecting);
        await whatsAppService.connect();
    }

    @Action
    public async listQueuesOfUser(): Promise<void> {
        if (Object.values(this.queues).length > 0) {
            return;
        }

        const queueClient = new QueueClient();

        const generalQueue: ListQueuesOfUser.IQueueUserDto = { id: Guid.Empty, name: "Geral" };

        const data = [];
        data.push(generalQueue);

        data.push(...(await queueClient.listQueuesOfUser({})));

        this.SET_QUEUES(data);
    }

    @Action
    notifyContextMenu() {
        notify("onConversationMessageBubbleContextMenuOpened");
    }

    get getReplyMessageByConversationId() {
        return (conversationId: Guid) => {
            return this.chats[conversationId.toString()].replyMessage;
        };
    }

    @Mutation
    public SET_REPLY_MESSAGE({ conversationId, message }: { conversationId: Guid; message: Message | null; }) {
        this.chats[conversationId.toString()].replyMessage = message;
        if (message != null) {
            notify("onConversationReplyTo", conversationId);
        }
    }
}

export const ConversationsModule = getModule(Conversations);
whatsAppService.bindConversationModule(ConversationsModule);
