import { RouteConfig } from 'vue-router';

export const ChatRouteName = 'Chat';

const chatRoutes: RouteConfig[] = [
    {
        path: '/chat',
        name: ChatRouteName,
        component: () => import('@/features/chat/chat-app.vue'),
        meta: { authorize: true }
    }
];

export default chatRoutes;