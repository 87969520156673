import VueRouter, { Route, NavigationGuardNext } from 'vue-router';
import { Dictionary } from 'vue-router/types/router';
import { AuthorizationModule } from '.';
import { AuthrorizationPaths, QueryParameterNames } from './constants';
import { IErrorParameters } from '@/features/error/error.interfaces';
import { encode } from "@/utils/base64";

function redirectToLogin(to: Route, next: NavigationGuardNext) {
    const query: Dictionary<string> = {};

    query[QueryParameterNames.ReturnUrl] = to.fullPath;

    next({
        path: AuthrorizationPaths.Login,
        query,
    });
}

// export interface Route {
//     path: string
//     name?: string | null
//     hash: string
//     query: Dictionary<string | (string | null)[]>
//     params: Dictionary<string>
//   }

// export interface Location {
//     name?: string
//     path?: string
//     hash?: string
//     query?: Dictionary<string | (string | null)[] | null | undefined>
//     params?: Dictionary<string>
//     append?: boolean
//     replace?: boolean
//   }

function redirectToError(message: string, from: Route, next: NavigationGuardNext) {
    const props: IErrorParameters = {
        type: 'error',
        message,
        go: {
            path: from.path,
            name: from.name ?? undefined,
            hash: from.hash,
            query: from.query,
            params: from.params,
        }
    };

    next({
        name: "Error",
        query: {
            error: encode(JSON.stringify(props))
        }
    });
}

async function validateRoles(to: Route): Promise<boolean> {
    const roles = (to.meta?.roles ?? []) as string[];
    if (roles.length <= 0) {
        return true;
    }
    return await AuthorizationModule.userHasRoles(...roles);
}

export function authorizeGuard(router: VueRouter): void {

    // Whenever a route changes,
    // this function is called before rendering the target path
    router.beforeEach(async (to, from, next) => {

        try {
            if (!(to.meta?.authorize as boolean ?? false)) {
                // Route does not require authorization
                next();
                return;
            }

            if (!AuthorizationModule.isAuthenticated) {
                // Route requires authorization and
                // User is not authenticated then
                // Redirect to login page
                redirectToLogin(to, next);
                return;
            }

            if (!(await validateRoles(to))) {
                // Route requires roles and
                // User do not has it
                // Redirect to error page
                redirectToError("Usuário não possui a designação para acessar esta área", from, next);
                return;
            }

            next();
        } catch (error) {
            console.error('authorizeGuard', error);
            throw error;
        }
    });
}
