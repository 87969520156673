<template>
    <v-card :class="isMobile? 'mx-auto pt-3' : 'mx-auto'" color="white" width="400px" tile>

        <v-card-title> MENU </v-card-title>
        <v-card-text>
            <div class="d-block">
                <v-text-field outlined v-model="title" :rules="rules.title" label="Título do Menu"></v-text-field>
                <v-textarea
                    outlined
                    auto-grow
                    rows="1"
                    v-model="text"
                    :rules="rules.text"
                    label="Texto do Menu"
                ></v-textarea>
                <v-radio-group v-model="menuMessageType" row>
                    <v-radio label="Botão" :value="MenuMessageType.Button"> </v-radio>
                    <v-radio label="Lista" :value="MenuMessageType.List"> </v-radio>
                </v-radio-group>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="removeButton" text color="error" @click="remove">Remover<v-icon>mdi-close</v-icon></v-btn>
        </v-card-actions>
    </v-card>
</template>


<script lang="ts">
import Vue from 'vue'
import { Component, Prop } from "vue-property-decorator";
import { Guid } from "@/utils/guid";
import * as validations from "@/utils/validations";
import { FormRules } from "@/components/action-controller.interfaces";
import ApiListMenus = Api.Companies.Queries.ListMenus;
import { Api } from "@/typings/api.typings";

const valuesDefault = {
    title: '',
    text: '',
    messageText: '',
    selectedQueues: null
};

@Component
export default class MenuBoxCard extends Vue {
    @Prop() remove: unknown;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Prop ({ required: true, default: () => ({ ...valuesDefault })}) values!: any;

    readonly id!: Guid | null;

    get isMobile() {
        return this.$vuetify.breakpoint.mobile;
    }

    get editing(): boolean {
        return this.id != null;
    }

    rules: FormRules = {
        $summary: [],
        title: [validations.requiredIf(!this.editing)],
        text: [validations.requiredIf(!this.editing)],
    };

    readonly MenuMessageType = {
        Button: ApiListMenus.MenuMessageType.Button,
        List: ApiListMenus.MenuMessageType.List,
    };

    get title(): string {
        return this.values.title;
    }
    set title(value: string) {
        this.values.title = value;
    }

    get text(): string {
        return this.values.text;
    }
    set text(value: string) {
        this.values.text = value;
    }

    get menuMessageType(): ApiListMenus.MenuMessageType {
        return this.values.menuMessageType;
    }
    set menuMessageType(value: ApiListMenus.MenuMessageType) {
        this.values.menuMessageType = value;
    }

    get removeButton(): boolean {
        return this.values.removeButton;
    }
    set removeButton(value: boolean) {
        this.values.removeButton = value;
    }
}
</script>
