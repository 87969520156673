import { Api } from '@/typings/api.typings';
import { Guid } from '@/utils/guid';
import { RouteConfig, Location } from 'vue-router';

const DefaultMessageListName = 'DefaultMessageList';
const DefaultMessageInsertName = 'DefaultMessageInsert'
const DefaultMessageEditName = 'DefaultMessageEdit'

export const DefaultMessageListRoute: Location = {
    name: DefaultMessageListName
}

export const DefaultMessageInsertRoute: Location = {
    name: DefaultMessageInsertName
}

export const DefaultMessageEditRoute = (id: Guid, message: string, type: Api.Domain.Enums.DefaultMessageType): Location => ({
    name: DefaultMessageEditName,
    params: {
        id: id.toString(),
        message,
        type: type.toString(),
    }
});

const defaultMessageRoutes: RouteConfig[] = [
    {
        path: '/default-messages',
        name: DefaultMessageListName,
        component: () => import('@/features/registration/companies/default-messages/default-messages-list.vue'),
        meta: { authorize: true }
    },
    {
        path: '/default-messages/insert',
        name: DefaultMessageInsertName,
        component: () => import('@/features/registration/companies/default-messages/default-messages-insert-edit.vue'),
        meta: { authorize: true },
    }, 
    {
        path: '/default-messages/edit/:id',
        name: DefaultMessageEditName,
        component: () => import('@/features/registration/companies/default-messages/default-messages-insert-edit.vue'),
        meta: { authorize: true },
        props: (route) => route.params
    }
];

export default defaultMessageRoutes;