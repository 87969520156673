import { Roles } from '@/constants';
import { Guid } from '@/utils/guid';
import { RouteConfig, Location } from 'vue-router';

export const DefaultPath = "/app-manager/company";

const CompanyListName = 'CompanyList';
const CompanyInsertName = 'CompanyInsert';
const CompanyEditName = 'CompanyEdit';

export const CompanyListRoute: Location = {
    name: CompanyListName
};

export const CompanyInsertRoute: Location = {
    name: CompanyInsertName
};

export const CompanyEditRoute = (id: Guid): Location => ({
    name: CompanyEditName,
    params: {
        id: id.toString(),
    }
});

const companiesRoutes: RouteConfig[] = [
    {
        path: DefaultPath,
        name: CompanyListName,
        component: () => import('@/features/app-manager/companies/company-list.vue'),
        meta: { authorize: true, roles: [Roles.NewsSoft, Roles.Support] }
    },
    {
        path: `${DefaultPath}/insert`,
        name: CompanyInsertName,
        component: () => import('@/features/app-manager/companies/company-insert-edit.vue'),
        meta: { authorize: true, roles: [Roles.NewsSoft, Roles.Support] }
    },
    {
        path: `${DefaultPath}/edit/:id`,
        name: CompanyEditName,
        component: () => import('@/features/app-manager/companies/company-insert-edit.vue'),
        meta: { authorize: true, roles: [Roles.NewsSoft, Roles.Support] },
        props: (route) => route.params
    }
];

export default companiesRoutes;
