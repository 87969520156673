import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import apiAuthorizationRoutes from "@/features/authentication/routes";
import { authorizeGuard } from "@/store/modules/authorization/authorize-guard";
import chatRoutes from "@/features/chat/routes";
import userRoutes from "@/features/registration/users/user-routes";
import AppManager from "@/features/app-manager/routes";
import errorRoutes from "@/features/error/error-routes";
import queueRoutes from "@/features/registration/companies/queues/queue-routes";
import menuRoutes from "@/features/registration/companies/menus/menu-routes";
import messageTemplateRoutes from "@/features/chat/parts/chat/parts/message-template/message-template-routes";
import reportsRoutes from "@/features/registration/reports/report-routes";
import reportRoutes from "@/features/report/routes";
import defaultMessagesRoutes from "@/features/registration/companies/default-messages/default-messages-routes";
import apiServicesRoutes from "./features/registration/companies/api-service-options/routes";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    ...apiAuthorizationRoutes,
    ...chatRoutes,
    ...userRoutes,
    ...AppManager,
    ...errorRoutes,
    ...queueRoutes,
    ...menuRoutes,
    ...messageTemplateRoutes,
    ...reportsRoutes,
    ...reportRoutes,
    ...defaultMessagesRoutes,
    ...apiServicesRoutes,
    {
        path: "/",
        name: "Home",
        redirect: { name: "Chat" }
    },
    {
        path: "/test",
        name: "Test",
        component: () => import("@/features/home/home.vue"),
    },
    {
        path: "/attendants",
        name: "ListarAtendentes",
        component: () => import("@/features/registration/attendants/list-attendants.vue"),
        meta: { authorize: true }
    },
    {
        path: "/schedule",
        name: "HorariosAtendentes",
        component: () => import("@/features/registration/opening-hours/schedule.vue"),
        meta: { authorize: true }
    },
    {
        path: "/managers",
        name: "ListarGerentes",
        component: () => import("@/features/registration/managers/list-managers.vue"),
        meta: { authorize: true }
    }
];

const router = new VueRouter({
    mode: process.env.CORDOVA_PLATFORM ? "hash" : "history",
    base: process.env.BASE_URL,
    routes
});

authorizeGuard(router);

export default router;
