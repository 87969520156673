<template>
    <v-dialog v-if="isMobile">
        <template v-slot:activator="{ on, attrs }">
            <v-card class="card-button" v-bind="attrs" v-on="on">
                <v-card-title class="justify-center"> OPÇÃO </v-card-title>
                <v-card-text class="text-center text-truncate"> {{ cardTitle? cardTitle : 'Clique aqui para editar' }} </v-card-text>
            </v-card>
        </template>
        <menu-option-box-card :values="values" :remove="remove" :queues="queues"></menu-option-box-card>
    </v-dialog>
    <menu-option-box-card v-else :values="values" :remove="remove" :queues="queues"></menu-option-box-card>
</template>

<style scoped>
.card-button {
    width: 190px;
    height: 95px;
    padding: 0px;
}
</style>
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { Api } from "@/typings/api.typings";

import { QueueClient } from "@/clients/queue-client";
import ApiListQueues = Api.Companies.Queries.ListQueues;
import MenuOptionBoxCard from "@/features/registration/companies/menus/menu-option-box-card.vue";

@Component({
    name: "MenuOptionBox",
    components: {
        MenuOptionBoxCard,
    },
})
export default class MenuOptionBox extends Vue {
    @Prop() remove: unknown;

    get isMobile() {
        return this.$vuetify.breakpoint.mobile;
    }

    get values(): any {
        const value = this.$attrs.node as any;

        if ("data" in value) {
            return value.data;
        } else return value;
    }

    get cardTitle(): string {
        return this.values.title
    }

    queueClient = new QueueClient();
    queues: ApiListQueues.IQueueDto[] = [];

    async listQueue(): Promise<void> {
        await this.$action.processAction({
            action: this.listQueueAction,
            showLoading: false,
            showLoadingOverlay: false,
            showSnackbar: false,
        });
    }

    async listQueueAction(): Promise<void> {
        const data = await this.queueClient.listQueues({
            pageSize: 999,
            page: 1,
        });

        this.queues.push(...data);
    }

    async mounted(): Promise<void> {
        await this.listQueue();
    }
}
</script>
