import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pt from "vuetify/src/locale/pt";
import en from "vuetify/src/locale/en";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        }
    },
    lang: {
        locales: {
            'pt-BR': pt,
            'pt-PT': pt,
            'pt': pt,
            'en': en,
            'en-US': en
        },
        current: navigator.language
    }
});
