import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

export const ApiServices = 'ApiServices';

Vue.use(VueRouter);

const apiServicesRoutes: RouteConfig[] = [
    {
        path: "/services",
        name: ApiServices,
        component: () => import("@/features/registration/companies/api-service-options/api-service-options-list.vue"),
        meta: { authorize: true }
    },
];

export default apiServicesRoutes;