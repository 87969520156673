import { Guid } from "@/utils/guid";
import { CustomerService } from "./customer-service";

export type ConversationModuleNotifications = {
    onConversationSelectedChanged: () => void;
    onConversationMessageBubbleContextMenuOpened: () => void;
    onConversationMessageAdded: (value: { conversationId: Guid, newMessages?: boolean; }) => void;
    onConversationCustomerServiceAdded: (value: CustomerService) => void;
    onConversationReplyTo: (conversationId: Guid) => void;
    onVelocityOfAudioChanged: (customerId: string) => void;
};

type ConversationEvents = {
    [K in keyof ConversationModuleNotifications]?: (ConversationModuleNotifications[K])[]
};

const events: ConversationEvents = {};

export const notify = function <K extends keyof ConversationModuleNotifications>(
    eventName: K,
    param?: Parameters<ConversationModuleNotifications[K]>[0]
): Promise<void> {
    const callbacks = events[eventName] as (ConversationModuleNotifications[K])[];
    if (!callbacks) {
        return Promise.resolve();
    }
    return new Promise((resolve) => {
        for (const callback of callbacks) {
            try {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                callback(param as any);
            } catch (error) {
                console.error(error);
            }
        }
        resolve();
    });
};

export const subscribe = function <K extends keyof ConversationModuleNotifications>(eventName: K, callback: ConversationModuleNotifications[K]): void {
    if (callback == null) {
        console.error("callback is null");
        return;
    }

    if (!events[eventName]) {
        events[eventName] = [];
    }
    events[eventName]!.push(callback);
};

export const unsubscribe = function <K extends keyof ConversationModuleNotifications>(eventName: K, callback: ConversationModuleNotifications[K]): void {
    const eventCallbacks = events[eventName];
    if (eventCallbacks) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (events as any)[eventName] = [...eventCallbacks.filter(cb => cb !== callback)];
    }
};
