import '@/utils/extensions';
import './plugins/flowy-vue';
import './registerServiceWorker';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import menuBox from '@/features/registration/companies/menus/menu-box.vue';
import menuOptionBox from '@/features/registration/companies/menus/menu-option-box.vue';
import VueMask from 'v-mask';

Vue.config.productionTip = process.env.NODE_ENV !== 'production';

Vue.component("MenuBox", menuBox);
Vue.component("MenuOptionBox", menuOptionBox);

Vue.use(VueMask);

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')

