import router from "@/router";
import { Dictionary } from "vue-router/types/router";
import { AuthrorizationPaths, QueryParameterNames } from "./constants";

export function redirectToLogin(): void {
    const query: Dictionary<string> = {};

    query[QueryParameterNames.ReturnUrl] = window.location.pathname + window.location.search

    router.push({
        path: AuthrorizationPaths.Login,
        query
    });
}

export function openUrl(url: string, readerMode = false) {
    if (process.env.CORDOVA_PLATFORM) {
        SafariViewController.isAvailable(function (available) {
            if (available) {
                SafariViewController.show({
                    url: url,
                    enterReaderModeIfAvailable: readerMode,
                });
            } else {
                cordova.InAppBrowser.open(url, '_blank')
            }
        });
    } else {
        window.open(url, '_blank');
    }
}
